import api from "./config";
import { encryptParams, encryptPayload } from "./encrypt";
import http from "./httpService";

const updateMenu = async (values) => {
  const result = await http.post(api + "/menu/update", encryptPayload(values));
  return result;
};
const createCoupon = async (values) => {
  const result = await http.post(
    api + "/create/subscription/coupon",
    encryptPayload(values)
  );
  return result;
};
const getMenuBySelId = async (values) => {
  const result = await http.get(
    api + "/menu/fetchBySelId/" + encryptParams(values?.toString())
  );
  return result;
};
const getAnalytics = async (values) => {
  const result = await http.post(
    api + "/analysis/byDay",
    encryptPayload(values)
  );
  return result;
};
const validateCoupon = async (values) => {
  const result = await http.post(
    api + "/validate/coupon",
    encryptPayload(values)
  );
  return result;
};
export {
  createCoupon,
  getAnalytics,
  getMenuBySelId,
  updateMenu,
  validateCoupon,
};
