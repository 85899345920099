import React, { useEffect, useContext } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { getDelivered, postDelivered } from "./api/order";
import DataContext from "./api/context";
import moment from "moment";
import { getMenuBySelId } from "./api/menu";
import { createRoute, getRoute } from "./api/route";
import DirectionsIcon from "@mui/icons-material/Directions";
import { Grid, IconButton, Typography } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { sendMessage } from "./api/message";
import SendIcon from "@mui/icons-material/Send";
export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [deliveredOrders, setDeliveredOrders] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchParams] = useSearchParams();
  const [SelID, setSelID] = React.useState();
  const sel = searchParams.get("sel");
  const dr = searchParams.get("driver");
  let { id } = useParams();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const fetchSortedOrders = async () => {
    const result = await getRoute(id);
    setSelID(result?.SelID);
    let sortArr = result?.sortedArray?.slice(1);
    // sortArr.shift();
    setRows(sortArr);
  };
  const fetchDeliveredOrders = async () => {
    const data = await getDelivered(SelID);
    setDeliveredOrders(data);
  };
  const notifyCustomer = async (phoneNumber) => {
    const result = await sendMessage({
      msg: "Your tiffin is delivered",
      phone: phoneNumber,
    });
  };
  useEffect(() => {
    fetchDeliveredOrders();
  }, [SelID]);
  useEffect(() => {
    fetchSortedOrders();
  }, [id]);
  return (
    rows?.length > 0 && (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Index</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Address</TableCell>
                <TableCell align="center">Phone Number</TableCell>
                <TableCell align="center">Directions</TableCell>
                <TableCell align="center">Deliver</TableCell>
                <TableCell align="center">Notify</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  let style = row?.request
                    ? { border: 0, backgroundColor: "#D3D3D3" }
                    : { backgroundColor: "white" };
                  let ph = "tel:" + row?.PhoneNumber;
                  return (
                    <>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        style={style}
                      >
                        <TableCell key={1} align="center" style={style}>
                          {index + 1}
                        </TableCell>
                        <TableCell key={1} align="center" style={style}>
                          {row?.FirstName} {row?.LastName}
                        </TableCell>
                        <TableCell key={1} align="center" style={style}>
                          {row?.address}
                        </TableCell>
                        <TableCell key={1} align="center" style={style}>
                          <a href={ph}>{row?.PhoneNumber}</a>
                        </TableCell>
                        <TableCell key={1} align="center" style={style}>
                          <IconButton>
                            <DirectionsIcon
                              style={{ color: "blue" }}
                              fontSize="large"
                              onClick={() =>
                                (window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${row?.coord.lat},${row?.coord.lng}`)
                              }
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell key={1} align="center" style={style}>
                          {/* postDelivered({SelID:sel,} */}
                          {deliveredOrders.includes(row._id) ? (
                            <IconButton>
                              <CheckCircleIcon
                                style={{ color: "#228b22" }}
                                fontSize="large"
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={async () => {
                                const confirmation = window.confirm(
                                  "Do you want to confirm that order is delivered? This action can't be undo."
                                );
                                if (confirmation) {
                                  setDeliveredOrders([
                                    row._id,
                                    ...deliveredOrders,
                                  ]);
                                  await postDelivered({
                                    SelID: SelID,
                                    order_id: row._id,
                                  });
                                }
                              }}
                            >
                              <CheckCircleIcon
                                style={{ color: "grey" }}
                                fontSize="large"
                              />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell key={1} align="center" style={style}>
                          <IconButton>
                            <SendIcon
                              style={{ color: "blue" }}
                              fontSize="large"
                              onClick={() => {
                                const confirmation = window.confirm(
                                  `Do you want to notify ${row?.FirstName} ${row?.LastName}?`
                                );
                                if (confirmation) {
                                  notifyCustomer(row?.PhoneNumber);
                                }
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {row?.request && (
                        <TableRow style={{ backgroundColor: "#D3D3D3" }}>
                          <TableCell colSpan={10} align="center">
                            <Typography style={{ fontWeight: "bold" }}>
                              **{row?.request}**
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    )
  );
  //   ) : (
  //     <Grid
  //       item
  //       xs={12}
  //       container
  //       justifyContent="center"
  //       style={{ marginTop: 50 }}
  //     >
  //       <Grid item xs={12} container justifyContent="center">
  //         <ErrorOutlineIcon style={{ color: "red" }} fontSize="large" />
  //       </Grid>
  //       <br />
  //       <Typography style={{ fontWeight: "bold", fontSize: 25 }}>
  //         Something went wrong
  //       </Typography>
  //     </Grid>
  //   );
}
