import Grid from "@mui/material/Grid";
import React from "react";
const Layout = (props) => {
  return (
    <Grid container direction="row">
      <Grid item lg={2} md={5} />
      <Grid item lg={10} md={7} sm={12} container direction="column">
        {props.children}
      </Grid>
    </Grid>
  );
};

export default Layout;
