import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import CryptoJS from "crypto-js";
import React, { useEffect } from "react";
import About from "./About";
import DataContext from "./api/context";
const HomePage = () => {
  const [side, setSide] = React.useState({
    left: false,
  });
  const { open, setOpen, loading, setLoading, user, setUser } =
    React.useContext(DataContext);
  useEffect(() => {
    try {
      if (localStorage.getItem("token")) {
        const decrypted = CryptoJS.AES.decrypt(
          localStorage.getItem("token"),
          process.env.REACT_APP_ENCRYPT_DATA
        );

        // Convert the decrypted data back to a JSON string
        const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

        // Parse the JSON string to get the original object
        const decryptedObject = JSON.parse(decryptedString);

        setUser(decryptedObject);
      }
    } catch (err) {
      localStorage.removeItem("token");
    }
  }, []);
  return (
    <>
      <Grid container>
        <Grid
          item
          lg={1}
          container
          style={{ backgroundColor: "black", color: "white" }}
        />
        <Grid
          item
          lg={11}
          md={12}
          container
          style={{ backgroundColor: "black", color: "white", padding: 20 }}
        >
          <Grid item xs={12} style={{ marginBottom: 70, marginTop: 70 }}>
            <Typography style={{ fontSize: 60 }}>DT Meals</Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <Typography
              style={{ fontSize: 30, fontWeight: "bold" }}
              gutterBottom
            >
              Partner with DT Meals
            </Typography>
            <Typography style={{ fontSize: 40 }} gutterBottom>
              Get started with automated meals subscription management system
            </Typography>
          </Grid>
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              borderRadius: 30,
              padding: 10,
              paddingInline: 20,
              marginBlock: 50,
              cursor: "pointer",
            }}
            onClick={() => setOpen(true)}
          >
            <Typography
              style={{
                fontSize: 30,
              }}
            >
              Sign up
            </Typography>
          </div>
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              borderRadius: 30,
              padding: 10,
              paddingInline: 20,
              marginBlock: 50,
              marginLeft: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              var targetDiv = document.getElementById("targetDiv");
              targetDiv.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <Typography
              style={{
                fontSize: 30,
              }}
            >
              Pricing
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ marginTop: 60 }}
        justifyContent="center"
      >
        <Alert severity="info">
          We're providing software for free of cost to{" "}
          <span style={{ fontWeight: "bolder" }}>first 50 partners</span>. Sign
          up today to be one of them or just give us a call at{" "}
          <a href="tel:+1-613-869-5322">613-869-5322</a>{" "}
        </Alert>
      </Grid>
      <Grid item xs={12} container style={{ marginTop: 120, marginBottom: 60 }}>
        {/* <Grid
          item
          sx={12}
          container
          justifyContent="center"
          alignContent="center"
        >
          <Typography variant="h3">Some of our top partners</Typography>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          style={{
            paddingTop: 20,
            justifyContent: "center",
            marginBlock: "30px",
          }}
          direction="row"
          container
        >
          <div onClick={() => {}}>
            <img
              src="https://static.wixstatic.com/media/c1001d_1f4ac2dc848449c490d12d893d8906dc~mv2.png"
              alt="Desi Tadka"
              style={{ maxHeight: "150px" }}
            />
          </div>
          <div>
            <img
              src="https://wedesiottawa.ca/assets/imgs/master-logo-compressed.PNG"
              alt="We desi"
              style={{ maxHeight: "150px" }}
            />
          </div>
        </Grid> */}
        <Grid
          item
          lg={12}
          md={12}
          container
          style={{ padding: 20, marginTop: 30 }}
        >
          <Grid
            item
            sx={12}
            container
            justifyContent="center"
            alignContent="center"
          >
            <Typography variant="h3">About Software</Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <Typography style={{ fontSize: 25 }} gutterBottom>
              {" "}
              Have a restaurant or passion for cooking? Want to provide meal
              subscriptions for side income?
              <span style={{ fontWeight: "bold" }}>
                {" "}
                DT Meals will take care of everything starting from getting
                subscriptions and orders, managing Subscription (plans, billing
                cycles, etc.), customizable meal plans, forecasting and
                restocking, message and email marketing .{" "}
              </span>
            </Typography>
            {/* <Typography style={{ fontSize: 40 }}>
              Get started with Canada’s one and only tiffin order management
              system
            </Typography> */}
          </Grid>
          <Grid
            item
            sx={12}
            container
            justifyContent="center"
            style={{ padding: 20 }}
          >
            <Grid item xs={12} style={{ paddingTop: 20 }}>
              <Typography style={{ fontSize: 25 }} gutterBottom>
                {" "}
                How can our software help?
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  Transform your small meal subscription side hustle into a
                  thriving business with our software, designed to streamline
                  operations, enhance customer experience, and drive growth.{" "}
                </span>
              </Typography>
              {/* <Typography style={{ fontSize: 40 }}>
              Get started with Canada’s one and only tiffin order management
              system
            </Typography> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          container
          style={{ padding: 20, marginBottom: 60 }}
        >
          {/* <Grid
            item
            sx={12}
            container
            justifyContent="center"
            alignContent="center"
          >
            <Typography variant="h3">Our solution</Typography>
          </Grid> */}
          <Grid
            item
            sx={12}
            container
            justifyContent="center"
            style={{ padding: 20 }}
          >
            <Grid item xs={12} style={{ paddingTop: 20 }}>
              <Typography style={{ fontSize: 25 }} gutterBottom>
                {" "}
                Our software provides ability to add customized orders according
                to your customer's needs.{" "}
                <span style={{ fontWeight: "bold" }}>
                  It's just take a single click to do
                </span>{" "}
                everything ( print stickers, notify customers about expiring
                orders, send custom bulk messages, get delivery route for
                multiple drivers, get history records, keeping track of payment
                and renewals of tiffin, etc). Custom features can be added
                according to your needs upon request.
              </Typography>
              {/* <Typography style={{ fontSize: 40 }}>
              Get started with Canada’s one and only tiffin order management
              system
            </Typography> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ marginTop: 50, marginBottom: 30 }}
        >
          <Typography variant="h3">Free Program</Typography>
        </Grid>
        <Grid item xs={12} container>
          <Grid item lg={3}></Grid>
          <Grid item md={12} lg={6}>
            <LinearProgress
              variant="determinate"
              value={42}
              style={{ height: 10, borderRadius: 10 }}
            />
          </Grid>
          <Grid item lg={3}></Grid>
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          <Typography style={{ fontWeight: "bold" }}>21 / 50</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ marginTop: 20 }}
        >
          <Typography style={{ fontSize: 25 }}>
            We're currently running free program for first 50 partners. Seats
            are getting filled up quickly. Get yours reserved today.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 30, cursor: "pointer" }}
          onClick={() => setOpen(true)}
        >
          <Typography style={{ fontSize: 30 }}>Sign up today</Typography>
          <IconButton>
            <ArrowForwardIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ marginBottom: 80 }}
        >
          <hr
            variant="middle"
            style={{
              height: 2,
              width: "12%",
              color: "black",
              backgroundColor: "black",
            }}
          />
        </Grid>
        <div id="targetDiv" />
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ marginTop: 50, marginBottom: 30 }}
        >
          <Typography variant="h3">Pricing</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ marginTop: 20 }}
        >
          <Typography style={{ fontSize: 25 }}>
            Unlock the power of premium software solutions, now available at an
            unbeatable flat rate of just{" "}
            <span style={{ fontWeight: "bold" }}>$50</span>! Email us at{" "}
            <Link href="mailto:support@dtmeals.com">support@dtmeals.com</Link>{" "}
            or call us at <a href="tel:+1-613-869-5322">613-869-5322</a> to get
            set up in minutes.
          </Typography>
        </Grid>
        {/* <Alert severity="info">
          We're providing software for free of cost to{" "}
          <span style={{ fontWeight: "bolder" }}>first 50 sellers</span>. Sign
          up today to be one of them or just give us a call at{" "}
          <a href="tel:+1-705-770-3061">705-770-3061</a>{" "}
        </Alert>
        <Grid item xs={12} container style={{ marginTop: 50 }}>
          <Typography variant="h3" gutterBottom>
            Let us do all hassle (management, marketing) for your tiffin
            service.
          </Typography>
          <Typography variant="h5" style={{ marginTop: 40 }} gutterBottom>
            We'll do everything for you so that you can focus on things that
            matters most for your business.
          </Typography>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
          container
          style={{ marginTop: "50px" }}
        >
          <Button variant="contained" onClick={() => setOpen(true)}>
            Sign Up/ Sign In
          </Button>
        </Grid> */}
        {/* <Grid item xs={12} container style={{ padding: 200 }}>
          <Divider variant="middle" style={{ fontWeight: 10, width: "100%" }} />
        </Grid> */}
        <About />
      </Grid>
    </>
  );
};

export default HomePage;
