import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { defaultMessages } from "./api/constants";
import DataContext from "./api/context";
import { getMenuBySelId, updateMenu } from "./api/menu";

const preDefinedMessages = defaultMessages;

const MessageTemplates = () => {
  const [message, setMessage] = useState("");
  const { user } = useContext(DataContext);
  const [messages, setMessages] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const caption = "FIRST NAME={{1}}, SERVICE NAME={{2}}";

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.trim()) {
      setMessages([...messages, message]);
      await updateMenu({
        SelID: user.SelID,
        messageTemplates: [...messages, message],
      });
      setMessage("");
    }
  };

  const handleDelete = async (index) => {
    if (index >= preDefinedMessages.length) {
      const newMessages = messages.filter(
        (_, i) => i !== index - preDefinedMessages.length
      );
      await updateMenu({
        SelID: user.SelID,
        messageTemplates: newMessages,
      });
      setMessages(newMessages);
    }
  };

  const handleInsertVariable = (variable) => {
    setMessage((prevMessage) => `${prevMessage}{{${variable}}}`);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchMessages = async () => {
      const {
        data: { messageTemplates },
      } = await getMenuBySelId(user.SelID);

      if (messageTemplates) {
        setMessages(messageTemplates);
      }
    };
    fetchMessages();
  }, [user.SelID]);

  return (
    <Container maxWidth="sm">
      <Card
        style={{ marginTop: "20px", maxHeight: "400px", overflow: "auto" }}
        elevation={3}
      >
        <CardContent>
          <Typography
            variant="h6"
            component="div"
            style={{ color: "#9f6ba0", fontWeight: "bold" }}
          >
            Message Templates ({messages.length + preDefinedMessages.length})
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            style={{ color: "gray" }}
          >
            {caption}
          </Typography>
          <List>
            {[...preDefinedMessages, ...messages].map((msg, index) => (
              <ListItem key={index} button onClick={() => handleCopy(msg)}>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "10px",
                    width: "100%",
                    backgroundColor: "#f9f9f9",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ListItemText
                    primary={
                      <pre style={{ whiteSpace: "pre-wrap" }}>{msg}</pre>
                    }
                  />
                  {index >= preDefinedMessages.length && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(index);
                      }}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </IconButton>
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Enter your message"
          variant="outlined"
          value={message}
          onChange={handleInputChange}
          margin="normal"
          multiline
          rows={4}
        />
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="overline" style={{ marginRight: "10px" }}>
              Add Variables:
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="overline"
              onClick={() => handleInsertVariable(1)}
              style={{
                marginRight: "10px",
                fontSize: 15,
                color: "#9f6ba0",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              First name
            </Typography>
            <Typography
              variant="overline"
              onClick={() => handleInsertVariable(2)}
              style={{
                marginRight: "10px",
                fontSize: 15,
                color: "#9f6ba0",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Service name
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            style={{ marginTop: "20px", backgroundColor: "#9f6ba0" }}
          >
            Submit
          </Button>
        </Grid>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Message copied to clipboard"
      />
    </Container>
  );
};

export default MessageTemplates;
