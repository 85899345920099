import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";

export default function BasicSelect({
  id,
  value,
  label,
  onChange,
  name,
  options,
  error,
  style,
  helperText,
}) {
  return (
    <>
      <FormControl fullWidth style={style}>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          labelId={id}
          id={id}
          value={value}
          label={label}
          name={name}
          onChange={onChange}
          error={error}
          helperText={helperText}
        >
          {options.map((option) => (
            <MenuItem value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
