import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	Paper,
	Switch,
	TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "./api/context";
import { createCoupon, getMenuBySelId, updateMenu } from "./api/menu";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
	},
	listItem: {
		borderBottom: "1px solid #ddd",
	},
	listHeader: {
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.default,
		borderBottom: "1px solid #ddd",
	},
	scrollableList: {
		height: "500px",
		maxWidth: "500px",
		minWidth: "300px",
		overflowY: "scroll",
		border: "1px solid #ccc",
		borderRadius: "8px",
		padding: "10px",
		boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
	},
	header: {
		paddingBottom: theme.spacing(1),
		borderBottom: "1px solid #ddd",
		marginBottom: theme.spacing(1),
		textAlign: "center",
		width: "100%",
	},
	listHeaderContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		minHeight: "100vh",
		padding: theme.spacing(2),
	},
	buttonContainer: {
		marginTop: theme.spacing(2),
		textAlign: "center",
	},
}));

const Coupon = () => {
	const classes = useStyles();
	const { user } = useContext(DataContext);
	const [open, setOpen] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		percent_off: 10.0,
		duration: "once",
		billingType: "subscription",
		minPrice: 0,
		disabled: false,
	});

	const [discounts, setDiscounts] = useState([]);
	const [errors, setErrors] = useState({
		name: "",
		percent_off: "",
		minPrice: "",
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const fetchCoupons = async () => {
			const {
				data: { coupons },
			} = await getMenuBySelId(user.SelID);
			setDiscounts(coupons);
		};
		fetchCoupons();
	}, [user]);

	const validate = () => {
		let tempErrors = {};
		const nameRegex = /^[A-Z0-9]+$/;
		tempErrors.name = formData.name ? "" : "This field is required.";
		if (formData.name && !nameRegex.test(formData.name)) {
			tempErrors.name =
				"Name must be in all capital letters, should not contain special characters, and no spaces.";
		}
		tempErrors.percent_off =
			formData.percent_off >= 0
				? ""
				: "Percent off must be a non-negative number.";
		tempErrors.minPrice =
			formData.minPrice >= 0
				? ""
				: "Minimum price must be a non-negative number.";
		setErrors(tempErrors);
		return Object.values(tempErrors).every((x) => x === "");
	};

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		if (name === "name") {
			const formattedValue = value.toUpperCase().replace(/\s/g, "");
			setFormData({
				...formData,
				[name]: formattedValue,
			});
		} else {
			setFormData({
				...formData,
				[name]:
					type === "checkbox"
						? checked
						: type === "number"
						? parseFloat(value)
						: value,
			});
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		// Check for duplicate name
		const duplicateName = discounts?.some(
			(discount) => discount.name === formData.name
		);
		if (duplicateName) {
			setErrors((prevErrors) => ({
				...prevErrors,
				name: "This name already exists.",
			}));
			return;
		}
		if (validate()) {
			await createCoupon({
				...formData,
				...{ SelID: user?.SelID, sellerAccount: user?.PayAccount },
			});
			setDiscounts([...discounts, formData]);
			setFormData({
				SelID: user.SelID,
				name: "",
				percent_off: 10.0,
				duration: "once",
				billingType: "subscription",
				minPrice: 0,
				disabled: false,
			});
			handleClose(); // Close the dialog after submission
			window.location.href = "/configuration";
		}
	};

	const toggleDisable = async (index, stripeCouponId) => {
		const newDiscounts = discounts?.map((discount, i) =>
			discount.stripeCouponId === stripeCouponId
				? { ...discount, disabled: !discount.disabled }
				: discount
		);
		const result = await updateMenu({
			SelID: user.SelID,
			coupons: newDiscounts,
		});
		setDiscounts(newDiscounts);
	};

	return (
		<Grid
			item
			xs={12}
			container
			justifyContent="center"
			alignItems="center"
			style={{ marginBottom: 20 }}
		>
			<Grid container justifyContent="center" alignItems="center">
				<Grid item>
					<Paper className={classes.root}>
						<div className={classes.scrollableList}>
							<h2 style={{ marginLeft: 10, color: "#9F6BA0" }}>
								Coupons ({discounts?.length})
							</h2>
							<List>
								{discounts?.map((discount, index) => (
									<ListItem key={index} className={classes.listItem}>
										<ListItemText
											primary={`${discount.name} - ${discount.percent_off}% off`}
											secondary={`Duration: ${discount.duration}, Billing Type: ${discount.billingType}, Minimum Price: $${discount.minPrice}`}
										/>
										<ListItemSecondaryAction>
											<Switch
												checked={discount.disabled}
												onChange={() =>
													toggleDisable(index, discount?.stripeCouponId)
												}
												color="primary"
											/>
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</List>
						</div>
					</Paper>
				</Grid>
			</Grid>

			<Grid xs={12} container justifyContent="center" alignItems="center">
				<Button
					variant="contained"
					style={{
						height: 40,
						marginTop: 20,
						backgroundColor: "#9F6BA0",
					}}
					onClick={handleClickOpen}
				>
					Add Coupon
				</Button>
			</Grid>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Coupon Form</DialogTitle>
				<DialogContent>
					<Box
						component="form"
						sx={{ mt: 2 }}
						noValidate
						autoComplete="off"
						onSubmit={handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									label="Name"
									name="name"
									value={formData.name}
									onChange={handleChange}
									fullWidth
									error={!!errors.name}
									helperText={errors.name}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Percent Off"
									name="percent_off"
									type="number"
									value={formData.percent_off}
									onChange={handleChange}
									inputProps={{ step: "0.01" }} // Ensure the input accepts double values
									fullWidth
									error={!!errors.percent_off}
									helperText={errors.percent_off}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Minimum Price"
									name="minPrice"
									type="number"
									value={formData.minPrice}
									onChange={handleChange}
									fullWidth
									error={!!errors.minPrice}
									helperText={errors.minPrice}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									select
									label="Duration"
									name="duration"
									value={formData.duration}
									onChange={handleChange}
									fullWidth
								>
									<MenuItem value="once">Once</MenuItem>
									<MenuItem value="forever">Forever</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={12}>
								<TextField
									select
									label="Billing Type"
									name="billingType"
									value={formData.billingType}
									onChange={handleChange}
									fullWidth
								>
									<MenuItem value="subscription">Subscription</MenuItem>
									{/* <MenuItem value="once">Once</MenuItem> */}
								</TextField>
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Switch
											checked={formData.disabled}
											onChange={handleChange}
											name="disabled"
											color="primary"
										/>
									}
									label="Disabled"
								/>
							</Grid>
						</Grid>
						<DialogActions>
							<Button onClick={handleClose} color="primary">
								Cancel
							</Button>
							<Button variant="contained" color="primary" type="submit">
								Submit
							</Button>
						</DialogActions>
					</Box>
				</DialogContent>
			</Dialog>
		</Grid>
	);
};

export default Coupon;
