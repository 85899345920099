import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import DataContext from "./api/context";
import { getAllRouteBySel } from "./api/route";
import { DataGrid } from "@mui/x-data-grid";
import { ConstructionOutlined } from "@mui/icons-material";
import { createExcel, createExcelData } from "./api/resources";
const Reports = () => {
  const getRowId = (row) => row._id;
  const { user } = useContext(DataContext);
  const [searchText, setSearchText] = useState();
  const [selectedDays, setSelectedDays] = useState(30);
  const [datesArr, setDatesArr] = useState([]);
  const [rows, setRows] = useState([]);
  const columns = [
    {
      field: "categories",
      headerName: "Categories",
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.driver.join(","),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "excel",
      headerName: "Excel",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: ({ row }) => {
        const { sortedArray, date, driver } = row;
        return (
          <Button
            onClick={async () => {
              const { orderArray, columns } = await createExcelData(
                sortedArray,
                date,
                driver.join(",")
              );
              createExcel(orderArray, columns, true);
            }}
          >
            Get in Excel
          </Button>
        );
      },
    },
  ];
  const setDatesArray = () => {
    let datesArray = [];
    const start = moment().subtract(selectedDays, "days").format("YYYY-MM-DD");
    const end = moment().format("YYYY-MM-DD");
    let startDate = moment(start);
    let endDate = moment(end);
    while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
      datesArray.push(startDate.format("YYYY-MM-DD")); // Add the date in the desired format
      startDate.add(1, "days"); // Increment the date by 1 day
    }
    setDatesArr(datesArray);
  };
  const getReports = async () => {
    const data = await getAllRouteBySel({
      SelID: user.SelID,
      dates: datesArr,
    });
    setRows(data.reverse());
  };

  useEffect(() => {
    setDatesArray();
    getReports();
  }, [selectedDays, user, rows]);
  return (
    <Grid container style={{ paddingRight: 30 }}>
      <Grid
        item
        xs={12}
        container
        style={{ padding: 20, paddingTop: 0, paddingLeft: 0 }}
      >
        <Typography variant="h4" fontWeight="bold">
          Older Reports
        </Typography>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12} container style={{ paddingInline: 5 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">For</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedDays}
              label="For"
              onChange={(e) => setSelectedDays(e.target.value)}
            >
              <MenuItem value={30}>Last 30 days</MenuItem>
              <MenuItem value={45}>Last 45 days</MenuItem>
              <MenuItem value={60}>Last 60 days</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {rows.length > 0 && (
        <Grid container style={{ padding: 5 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 20, 50]}
            //   checkboxSelection
            getRowId={getRowId}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Reports;
