import api from "./config";
import { encryptParams, encryptPayload } from "./encrypt";
import http from "./httpService";
const getPendingSubscriptionById = async (values) => {
  const result = await http.get(
    api + "/pending/subscription/" + encryptParams(values)
  );
  return result;
};
const getPendingSubscriptions = async (values) => {
  const { data } = await http.post(
    api + "/pending/subscriptions/",
    encryptPayload(values)
  );
  return data;
};
const confirmSubscription = async (values) => {
  const { data } = await http.post(
    api + "/confirm/subscription",
    encryptPayload(values)
  );
  return data;
};
const updateSubscription = async (values) => {
  const { data } = await http.post(
    api + "/seller/subscriptions/update",
    encryptPayload(values)
  );
  return data;
};
const getSubscriptionsBySel = async (values) => {
  const { data } = await http.post(
    api + "/seller/subscriptions",
    encryptPayload(values)
  );
  return data;
};
const getAnySubscriptions = async (values) => {
  const { data } = await http.post(
    api + "/subscriptions/any",
    encryptPayload(values)
  );
  return data;
};
const deletePendingSubscription = async (values) => {
  const { data } = await http.post(
    api + "/delete/pending/subscriptions",
    encryptPayload(values)
  );
  return data;
};
const cancelSubscriptionAtEnd = async (values) => {
  const result = await http.post(
    api + "/cancel/subscription",
    encryptPayload(values)
  );
  return result;
};
const resumeCancelSubscriptionAtEnd = async (values) => {
  const result = await http.post(
    api + "/resume/subscription",
    encryptPayload(values)
  );
  return result;
};
export {
  cancelSubscriptionAtEnd,
  confirmSubscription,
  deletePendingSubscription,
  getAnySubscriptions,
  getPendingSubscriptionById,
  getPendingSubscriptions,
  getSubscriptionsBySel,
  resumeCancelSubscriptionAtEnd,
  updateSubscription
};

