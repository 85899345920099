import axios from "axios";
import api from "./config";
import { encryptParams, encryptPayload } from "./encrypt";
import http from "./httpService";
const login = async (values) => {
  const result = await axios.post(
    api + "/seller/checkin",
    encryptPayload(values)
  );
  return result;
};

const signup = async (values) => {
  const result = await axios.post(
    api + "/seller/signup",
    encryptPayload(values)
  );
  return result;
};

const getOnboardUrl = async (values) => {
  const result = await http.get(api + "/seller/onboard/" + values);
  return result;
};

const getStripeAcc = async (values) => {
  const result = await http.get(
    api + "/seller/retaccount/" + encryptParams(values)
  );
  return result;
};

const getStripeUrl = async (values) => {
  const result = await http.get(api + "/seller/payaccount/" + values);
  return result;
};

const saveStripeAccessKey = async (values) => {
  try {
    const result = await http.post(
      api + "/seller/stripeAccessToken",
      encryptPayload(values)
    );

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

const checkStripeAccessToken = async (values) => {
  const result = await axios.get(
    api + "/seller/checkStripeAccessToken/" + encryptParams(values?.toString())
  );
  return result;
};

const calEndDate = async (values) => {
  const { data } = await axios.post(
    api + "/seller/calculateEndDayCustomer",
    encryptPayload(values)
  );
  return data;
};
export {
  calEndDate,
  checkStripeAccessToken,
  getOnboardUrl,
  getStripeAcc,
  getStripeUrl,
  login,
  saveStripeAccessKey,
  signup,
};
