import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

// Sample data
const menuData = [
  {
    prodName: "Product A",
    menuOpt: "Option 1",
    rice: 2,
    roti: 3,
    dal: 1,
    sabzi: 2,
  },
  {
    prodName: "Product A",
    menuOpt: "Option 2",
    rice: 1,
    roti: 6,
    dal: 3,
    sabzi: 1,
  },
  {
    prodName: "Product A",
    menuOpt: "Option 1",
    rice: 3,
    roti: 1,
    dal: 2,
    sabzi: 3,
  },
  {
    prodName: "Product B",
    menuOpt: "Option 1",
    rice: 3,
    roti: 5,
    dal: 2,
    sabzi: 3,
  },
  // Add more data as needed
];

// Function to group data by prodName and menuOpt
function groupByProdNameAndMenuOpt(data) {
  return data.reduce((acc, item) => {
    if (!acc[item.prodName]) {
      acc[item.prodName] = {};
    }
    if (!acc[item.prodName][item.menuOpt]) {
      acc[item.prodName][item.menuOpt] = [];
    }
    acc[item.prodName][item.menuOpt].push(item);
    return acc;
  }, {});
}

// Function to calculate statistics for each group and overall totals
function calculateStatsForGroups(groupedData) {
  const stats = {
    overall: {
      totalEntries: 0,
      riceTotal: 0,
      rotiTotal: 0,
      dalTotal: 0,
      sabziTotal: 0,
    },
    details: {},
  };

  for (const prodName in groupedData) {
    stats.details[prodName] = {};
    for (const menuOpt in groupedData[prodName]) {
      const items = groupedData[prodName][menuOpt];
      const prodStats = {
        totalEntries: items.length,
        riceTotal: 0,
        rotiTotal: 0,
        rotiPacks: {},
        dalTotal: 0,
        sabziTotal: 0,
      };

      items.forEach((item) => {
        prodStats.riceTotal += item.rice;
        prodStats.dalTotal += item.dal;
        prodStats.sabziTotal += item.sabzi;
        prodStats.rotiTotal += item.roti;

        stats.overall.totalEntries += 1;
        stats.overall.riceTotal += item.rice;
        stats.overall.dalTotal += item.dal;
        stats.overall.sabziTotal += item.sabzi;
        stats.overall.rotiTotal += item.roti;

        if (!prodStats.rotiPacks[item.roti]) {
          prodStats.rotiPacks[item.roti] = 0;
        }
        prodStats.rotiPacks[item.roti] += 1;
      });

      prodStats.riceAverage = (
        prodStats.riceTotal / prodStats.totalEntries
      ).toFixed(2);
      prodStats.dalAverage = (
        prodStats.dalTotal / prodStats.totalEntries
      ).toFixed(2);
      prodStats.sabziAverage = (
        prodStats.sabziTotal / prodStats.totalEntries
      ).toFixed(2);

      stats.details[prodName][menuOpt] = prodStats;
    }
  }

  return stats;
}

const Stats = ({ data }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const groupedData = groupByProdNameAndMenuOpt(data);
  const stats = calculateStatsForGroups(groupedData);

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={handleOpen}
        style={{ marginRight: 5, color: "#9f6ba0" }}
      >
        STATS
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h5">
            STATS
          </Typography>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom>
              Overall Totals
            </Typography>
            <Typography>
              <strong>Count:</strong> {stats.overall.totalEntries}
            </Typography>
            <Typography>
              <strong>Total Rice:</strong> {stats.overall.riceTotal}
            </Typography>
            <Typography>
              <strong>Total Dal:</strong> {stats.overall.dalTotal}
            </Typography>
            <Typography>
              <strong>Total Sabzi:</strong> {stats.overall.sabziTotal}
            </Typography>
            <Typography>
              <strong>Total Rotis:</strong> {stats.overall.rotiTotal}
            </Typography>
          </Box>
          {Object.keys(stats.details).map((prodName) => (
            <div key={prodName} style={{ marginBlock: 10 }}>
              <Typography variant="h5" gutterBottom>
                {prodName}
              </Typography>
              <Grid container spacing={3}>
                {Object.keys(stats.details[prodName]).map((menuOpt) => {
                  const menuStats = stats.details[prodName][menuOpt];
                  return (
                    <Grid item xs={12} sm={6} md={4} key={menuOpt}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {menuOpt}
                          </Typography>
                          <Typography>
                            <strong>Count:</strong> {menuStats.totalEntries}
                          </Typography>
                          <Typography>
                            <strong>Total Rice:</strong> {menuStats.riceTotal}
                          </Typography>
                          <Typography>
                            <strong>Total Dal:</strong> {menuStats.dalTotal}
                          </Typography>
                          <Typography>
                            <strong>Total Sabzi:</strong> {menuStats.sabziTotal}
                          </Typography>
                          <Typography>
                            <strong>Total Rotis:</strong> {menuStats.rotiTotal}
                          </Typography>
                          {Object.keys(menuStats.rotiPacks).map((quantity) => (
                            <Typography key={quantity}>
                              {menuStats.rotiPacks[quantity]} packs of{" "}
                              {quantity} rotis
                            </Typography>
                          ))}
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          ))}
        </Box>
      </Modal>
    </>
  );
};

export default Stats;
