import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import TuneIcon from "@mui/icons-material/Tune";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Table,
	TextField,
	Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PrintExcel from "../PrintExcel";
import { getArea } from "../api/area";
import DataContext from "../api/context";
import { getMenuBySelId } from "../api/menu";
import { sendMessage } from "../api/message";
import { deleteOrder } from "../api/order";
import { checkDate } from "../api/resources";
import CustomMessage from "./CustomMessage";
import OrderDetails from "./OrderDetails";
import OrderHistory from "./OrderHistory";
import Stats from "./Stats";
const styleHead = {
	backgroundColor: "black",
	color: "white",
	fontWeight: "bold",
	whiteSpace: "nowrap",
};
const codeNames = {
	FirstName: "First Name",
	LastName: "Last Name",
	PhoneNumber: "Phone Number",
	rrOpt: "Rice/Roti Options",
	rice: "Rice",
	dal: "Dal",
	sabzi: "Sabzi",
	roti: "Roti",
	driver: "Categories",
	pause: "Paused",
	serviceOpt: "Service Type",
	sDate: "Start Date",
	eDate: "End Date",
	request: "Request",
	address: "Address",
	price: "Price",
	menuOpt: "Menu Type",
};
const styleCell = { whiteSpace: "nowrap", borderBottom: 0 };

export default function TableComponent({
	rows,
	action,
	handleSeenMarked,
	reorder,
	msg = false,
	search,
	cspan,
	activeDate,
	setActiveDate,
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	excludePaused,
	setExcludePaused,
	onlyToday,
	setOnlyToday,
	uniqueCus,
	setUniqueCus,
}) {
	const [orderType, setOrderType] = React.useState("All");
	const [orders, setOrders] = React.useState([]);
	const [searched, setSearched] = React.useState("");
	const [data, setData] = React.useState("");
	const [filteredRows, setFilteredRows] = React.useState([]);
	const [estDialog, setEstDialog] = React.useState(false);
	const [areaFilteredRows, setAreaFilteredRows] = React.useState([]);
	const [menu, setMenu] = React.useState();
	const [orderBy, setOrderBy] = React.useState("none");
	const [filterD, setFilterD] = React.useState(false);
	const [expDate, setExpDate] = React.useState();
	const [selProdName, setSelProdName] = React.useState();
	const [stats, setStats] = React.useState({});
	const [expired, setExpired] = React.useState(false);
	const [unpaid, setUnpaid] = React.useState(false);
	const [areas, setAreas] = React.useState([]);
	const [holidays, setHolidays] = React.useState([]);
	const { user, setLoading } = React.useContext(DataContext);
	const [areaN, setAreaN] = React.useState();
	const [month, setMonth] = React.useState();
	const [currentPage, setCurrentPage] = React.useState(1);
	const [totalPage, setTotalPage] = React.useState(0);
	const [openHistory, setOpenHistory] = React.useState();
	const [itemCount, setItemCount] = React.useState(20);
	const [prodOptions, setProdOptions] = React.useState([]);
	const monthList = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const navigate = useNavigate();
	const getMenu = async () => {
		const { data } = await getMenuBySelId(user.SelID);
		const { products } = data;
		setProdOptions(products.map((obj) => obj.prodName));
		setData(data);
	};
	React.useEffect(() => {
		getMenu();
		setOrders(rows);
		setFilteredRows(rows);
		setAreaFilteredRows(rows);
	}, [rows]);
	const handleTypeChange = (e) => {
		if (e.target.value === "Special") {
			setOrders(rows.filter((order) => order?.request));
		} else if (e.target.value === "Regular") {
			setOrders(rows.filter((order) => !order?.request));
		} else {
			setOrders(rows);
		}
		setOrderType(e.target.value);
	};

	const requestSearch = (value = "") => {
		let filter = orders.filter(
			(row) =>
				(areaN ? row.driver === areaN : true) &&
				row.FirstName.toLowerCase().startsWith(value.toLowerCase()) &&
				(expDate ? row.calEndDate === expDate : true) &&
				(menu ? row.menuOpt === menu : true) &&
				(expired ? moment().isAfter(row?.calEndDate) : true) &&
				(unpaid ? row?.price.toString() === "0" : true) &&
				(month ? moment(row?.sDate, "YYYY-MM-DD").month() === month : true) &&
				(selProdName ? row?.prodName === selProdName : true)
		);
		if (orderBy === "endAsc") {
			filter = _.orderBy(filter, ["calEndDate"], ["asc"]);
		} else if (orderBy === "startAsc") {
			filter = _.orderBy(filter, ["sDate"], ["asc"]);
		} else if (orderBy === "startDesc") {
			filter = _.orderBy(filter, ["sDate"], ["desc"]);
		} else if (orderBy === "endDesc") {
			filter = _.orderBy(filter, ["calEndDate"], ["desc"]);
		} else if (orderBy === "orderAsc") {
			filter = _.orderBy(filter, ["date"], ["asc"]);
		} else if (orderBy === "orderDesc") {
			filter = _.orderBy(filter, ["date"], ["desc"]);
		}
		setFilteredRows(filter);
	};
	const fetchOptions = async () => {
		let arr = [];

		if (data?.holidays) {
			setHolidays(data?.holidays);
		}
		const { areas } = await getArea(user.SelID);
		setAreas(areas);
	};
	const notify = async () => {
		setLoading(true);

		filteredRows.map(async (row) => {
			let exp = true;
			exp = checkDate(row.sDate, row.calEndDate);
			const result = await sendMessage({
				messageTemplateId: "order_confirmation",
				PhoneNumber: row.PhoneNumber,
				FirstName: row.FirstName,
			});
		});
		setLoading(false);
		window.alert("Notifications Sent!");
	};
	const customMsg = async (msg) => {
		setLoading(true);
		filteredRows.map(async (row) => {
			const result = await sendMessage({
				messageTemplateId: "order_confirmation",
				PhoneNumber: row.PhoneNumber,
				FirstName: row.FirstName,
			});
		});
		setLoading(false);
		window.alert("Notifications Sent!");
	};
	React.useEffect(() => {
		fetchOptions();
	}, [user]);
	React.useEffect(() => {
		setCurrentPage(1);
		setTotalPage(Math.ceil(filteredRows.length / itemCount));
	}, [filteredRows]);
	React.useEffect(() => {
		let roti = 0;
		let rice = 0;
		let veg = 0;
		let nveg = 0;
		let sabzi = 0;
		let dal = 0;
		filteredRows.map((row) => {
			if (
				row.selDays ? row.selDays?.includes(moment(activeDate).day()) : true
			) {
				if (row?.menuOpt === "Veg Menu") {
					veg++;
				} else {
					nveg++;
				}
				roti += row?.roti ?? 0;
				rice += row?.rice ?? 0;
				sabzi += row?.sabzi ?? 0;
				dal += row?.dal ?? 0;
			} else {
			}
		});
		setStats({
			veg: veg,
			nveg: nveg,
			roti: roti,
			rice: rice,
			dal: dal,
			sabzi: sabzi,
		});
	}, [filteredRows]);
	const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	const getDaysNote = (selDays = []) => {
		let daysNote = " ";
		if (_.isEqual(selDays?.sort(), data?.selDays?.sort())) {
			return daysNote;
		} else {
			daysNote += " ( ";
			selDays && selDays?.sort();
			selDays.map((id) => {
				daysNote = daysNote + days[id] + " ";
			});
			daysNote += " ) ";
			return daysNote;
		}
	};
	return (
		<>
			{search ? (
				<></>
			) : (
				<>
					<Dialog onClose={() => setFilterD(false)} open={filterD} fullWidth>
						<DialogTitle>Filters</DialogTitle>
						<DialogContent dividers>
							{areas && (
								<FormControl fullWidth style={{ minWidth: 50 }}>
									<InputLabel id="area">Category</InputLabel>
									<Select
										labelId="area"
										id="area"
										value={areaN}
										label="Category"
										onChange={(e) => {
											setAreaN(e.target.value);
											setSearched("");
										}}
									>
										{areas.map((option) => (
											<MenuItem value={option.id}>
												Category - {option.id} {option.aname}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
							{prodOptions.length > 0 && (
								<FormControl fullWidth style={{ minWidth: 50, marginTop: 20 }}>
									<InputLabel id="prodName">Product</InputLabel>
									<Select
										labelId="prodName"
										id="prodName"
										value={selProdName}
										label="Product"
										onChange={(e) => {
											setSelProdName(e.target.value);
											setSearched("");
										}}
									>
										{prodOptions.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
							{!msg && (
								<>
									<Typography style={{ marginTop: 20, marginBottom: 10 }}>
										Expiry Date
									</Typography>
									<TextField
										id="expDate"
										name="expDate"
										type="date"
										variant="outlined"
										fullWidth
										value={expDate}
										onChange={(e) => setExpDate(e.target.value)}
									/>
								</>
							)}
							<FormControl fullWidth style={{ minWidth: 50, marginBlock: 20 }}>
								<InputLabel id="menu">Menu Type</InputLabel>
								<Select
									labelId="menu"
									id="menu"
									value={menu}
									label="Menu Type"
									onChange={(e) => {
										setMenu(e.target.value);
										setSearched("");
									}}
								>
									<MenuItem value="Veg Menu">Veg</MenuItem>
									<MenuItem value="Non-Veg Menu">Non Veg</MenuItem>
								</Select>
							</FormControl>
							<FormControl fullWidth>
								<InputLabel id="month">Start Month</InputLabel>
								<Select
									labelId="month"
									id="month"
									value={month}
									label="Month"
									onChange={(e) => setMonth(e.target.value)}
								>
									{monthList.map((mon, index) => (
										<MenuItem value={index}>{mon}</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl fullWidth style={{ minWidth: 50, marginBlock: 20 }}>
								<InputLabel id="orderBy">Order By</InputLabel>
								<Select
									labelId="orderBy"
									id="orderBy"
									value={orderBy}
									label="Order By"
									onChange={(e) => {
										setOrderBy(e.target.value);
										setSearched("");
									}}
								>
									<MenuItem value="none">None</MenuItem>
									<MenuItem value="startAsc">Start Date - Ascending</MenuItem>
									<MenuItem value="startDesc">Start Date - Descending</MenuItem>
									<MenuItem value="endAsc">End Date - Ascending</MenuItem>
									<MenuItem value="endDesc">End Date - Descending</MenuItem>
									<MenuItem value="orderAsc">Ordered Date - Ascending</MenuItem>
									<MenuItem value="orderDesc">
										Ordered Date - Descending
									</MenuItem>
								</Select>
							</FormControl>
							{/* <Typography>Expired Only</Typography>
              <Switch
                checked={expired}
                onChange={(e) => setExpired(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              /> */}
							<Typography>Unpaid</Typography>
							<Switch
								checked={unpaid}
								onChange={(e) => setUnpaid(e.target.checked)}
								inputProps={{ "aria-label": "controlled" }}
							/>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								onClick={() => {
									setAreaN();
									setFilterD(false);
									setFilteredRows(orders);
									setExpDate();
									setExpired(false);
									setUnpaid(false);
									setOrderBy("none");
									setSelProdName();
								}}
							>
								Reset
							</Button>
							<Button
								autoFocus
								onClick={() => {
									requestSearch();
									setFilterD(false);
								}}
							>
								Apply
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						onClose={() => setEstDialog(false)}
						open={estDialog}
						fullWidth
					>
						<DialogTitle>Stats</DialogTitle>

						<DialogContent dividers>
							<Typography>Roti - {stats?.roti}</Typography>
							<Typography>Rice - {stats?.rice}</Typography>
							<Typography>Dal - {stats?.dal}</Typography>
							<Typography>Sabzi - {stats?.sabzi}</Typography>
							<Typography>Veg - {stats?.veg}</Typography>
							<Typography>Non-Veg - {stats?.nveg}</Typography>
							<Typography style={{ marginTop: 10, fontWeight: "bold" }}>
								Total - {parseInt(stats?.veg) + parseInt(stats?.nveg)}
							</Typography>
							<Typography style={{ marginTop: 20, fontWeight: "bold" }}>
								This count only includes orders that are scheduled for{" "}
								{activeDate} ({moment(activeDate).format("dddd")})
							</Typography>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								onClick={() => {
									setEstDialog(false);
								}}
							>
								Close
							</Button>
						</DialogActions>
					</Dialog>
					<Grid item xs={12} container>
						<Grid item xs={10}>
							<TextField
								fullWidth
								value={searched}
								placeholder="Search name"
								onChange={(e) => {
									setSearched(e.target.value);
									requestSearch(e.target.value);
								}}
							/>
						</Grid>

						<Grid
							item
							xs={2}
							justifyContent="center"
							alignItems="center"
							container
						>
							<IconButton size="large" onClick={() => setFilterD(true)}>
								<TuneIcon fontSize="large" />
							</IconButton>
						</Grid>
					</Grid>
				</>
			)}
			{!msg && action !== "paused" && action !== "adjust" && (
				<Grid
					item
					xs={12}
					container
					alignItems="center"
					style={{ marginBlock: 20 }}
				>
					<Typography style={{ marginBottom: 10 }}>Active Date</Typography>
					<TextField
						id="activeDate"
						name="activeDate"
						type="date"
						variant="outlined"
						fullWidth
						value={activeDate}
						onChange={(e) => setActiveDate(e.target.value)}
					/>
				</Grid>
			)}
			{action === "adjust" && (
				<>
					<Typography style={{ marginTop: 20, marginBottom: 10 }}>
						Start Date
					</Typography>
					<TextField
						id="startDate"
						name="startDate"
						type="date"
						variant="outlined"
						fullWidth
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
					/>
				</>
			)}
			{/* {action === "adjust" && (
        <>
          <Typography style={{ marginTop: 20, marginBottom: 10 }}>
            End Date
          </Typography>
          <TextField
            id="endDate"
            name="endDate"
            type="date"
            variant="outlined"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </>
      )} */}
			<Grid
				item
				xs={12}
				container
				alignItems="center"
				style={{ marginBlock: 20 }}
			>
				<Grid item xs={6}>
					<Box display="flex" alignItems="center">
						{action === "details" && <Stats data={filteredRows} />}
						<Typography variant="button" style={{ marginRight: 15 }}>
							Count - {filteredRows?.length}
						</Typography>
						{action === "details" && (
							<FormControlLabel
								control={<Switch />}
								label="Exclude Paused Orders"
								checked={excludePaused}
								onChange={(value) => setExcludePaused(value.target.checked)}
							/>
						)}
						{action === "details" && (
							<FormControlLabel
								control={<Switch />}
								label="Today's only"
								checked={onlyToday}
								onChange={(value) => setOnlyToday(value.target.checked)}
							/>
						)}
						{action === "adjust" && (
							<FormControlLabel
								control={<Switch />}
								label="Unique Customers"
								checked={uniqueCus}
								onChange={(value) => setUniqueCus(value.target.checked)}
							/>
						)}
					</Box>
				</Grid>
				<Grid item xs={6} container alignItems="right" justifyContent="right">
					<PrintExcel data={filteredRows} />
					<CustomMessage data={filteredRows} />
				</Grid>
			</Grid>
			{/* <BasicSelect
        style={{ marginBlock: 10 }}
        label="Order Type"
        handleChange={handleTypeChange}
        value={orderType}
      /> */}
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell align="center" style={styleHead}>
								History
							</TableCell>
							{!search && (
								<TableCell align="center" style={styleHead}>
									Exclude
								</TableCell>
							)}
							<TableCell align="center" style={styleHead}>
								ID
							</TableCell>
							<TableCell align="center" style={styleHead}>
								Start
							</TableCell>
							<TableCell align="center" style={styleHead}>
								End
							</TableCell>
							<TableCell align="center" style={styleHead}>
								Name
							</TableCell>
							<TableCell align="center" style={styleHead}>
								Rice/Roti
							</TableCell>
							{/* <TableCell align="center" style={styleHead}>
                Raita/Salad
              </TableCell> */}
							<TableCell align="center" style={styleHead}>
								Address
							</TableCell>
							{/* {action === "adjust" && (
                <TableCell align="center" style={styleHead}>
                  Adjust
                </TableCell>
              )} */}
							{(action === "details" ||
								action === "paused" ||
								action === "adjust") && (
								<TableCell align="center" style={styleHead}>
									Edit
								</TableCell>
							)}
							{reorder && (
								<>
									<TableCell align="center" style={styleHead}>
										Reorder
									</TableCell>
									<TableCell align="center" style={styleHead}>
										Delete
									</TableCell>
								</>
							)}
							{msg && (
								<TableCell align="center" style={styleHead}>
									Notify
								</TableCell>
							)}
							<TableCell align="center" style={styleHead}>
								Action
							</TableCell>
						</TableRow>
					</TableHead>
					{filteredRows && (
						<TableBody>
							{filteredRows
								.slice(
									itemCount * (currentPage - 1),
									itemCount * (currentPage - 1) + itemCount
								)
								.map((row, index) => (
									<React.Fragment key={row.name}>
										<TableRow
											key={row.name}
											sx={
												row?.request ||
												row?.price === 0 ||
												(!_.isEqual(
													row?.selDays?.sort(),
													data?.selDays?.sort()
												) &&
													!!row?.selDays)
													? {
															"& td": { border: 0 },
													  }
													: {
															"& > *": { border: 0 },
													  }
											}
											style={styleCell}
										>
											<TableCell style={styleCell}>
												<IconButton
													aria-label="expand row"
													size="small"
													onClick={() => {
														if (index === openHistory) {
															setOpenHistory();
														} else {
															setOpenHistory(index);
														}
													}}
												>
													{openHistory === index ? (
														<KeyboardArrowUpIcon />
													) : (
														<KeyboardArrowDownIcon />
													)}
												</IconButton>
											</TableCell>
											{!search && (
												<TableCell align="center" style={styleCell}>
													<IconButton
														onClick={() =>
															setFilteredRows(
																filteredRows.filter(
																	(ro) => row?._id !== ro?._id
																)
															)
														}
													>
														<RemoveCircleOutlineIcon
															fontSize="large"
															style={{ color: "red" }}
														/>
													</IconButton>
												</TableCell>
											)}
											<TableCell align="center" style={styleCell}>
												{row?._id?.slice(-5)}
											</TableCell>
											<TableCell align="center" style={styleCell}>
												{row?.sDate}
											</TableCell>
											<TableCell align="center" style={styleCell}>
												{row?.calEndDate}
											</TableCell>
											<TableCell align="center" style={styleCell}>
												{row?.FirstName}
											</TableCell>
											<TableCell align="center" style={styleCell}>
												{typeof row?.rrOpt === "string"
													? row?.rrOpt
													: row?.rrOpt["roti"] +
													  " Roti & " +
													  row?.rrOpt["rice"] +
													  " Rice"}
											</TableCell>
											{/* <TableCell align="center" style={styleCell}>
                        {row?.rsOpt}
                      </TableCell> */}
											<TableCell align="center" style={styleCell}>
												{row?.address}
											</TableCell>
											{(action === "details" || action === "paused") &&
												row?.isManual && (
													<TableCell align="center" style={styleCell}>
														<Button
															variant="outlined"
															onClick={(event) => {
																const newPath = `/edit/order/${row._id}`;

																if (event.ctrlKey) {
																	// If Ctrl key is pressed, open the URL in a new tab
																	window.open(newPath, "_blank");
																} else {
																	// Otherwise, navigate to the new path in the same tab
																	navigate(newPath);
																}
															}}
														>
															Edit
														</Button>
													</TableCell>
												)}
											{action === "adjust" && row?.isManual && (
												<TableCell align="center" style={styleCell}>
													<Button
														variant="outlined"
														onClick={() => navigate(`/past/order/${row._id}`)}
													>
														Edit
													</Button>
												</TableCell>
											)}
											{(action === "details" || action === "paused") &&
												row?.isPaid &&
												row?.billingType === "subscription" && (
													<TableCell align="center" style={styleCell}>
														<Button
															variant="outlined"
															onClick={() =>
																navigate(
																	`/edit/subscription/${row.subscriptionId}`
																)
															}
														>
															Edit Sub
														</Button>
													</TableCell>
												)}
											{/* {action === "adjust" && row?.isManual && (
                      <TableCell align="center" style={styleCell}>
                        <Button
                          variant="outlined"
                          onClick={() => navigate(`/edit/order/${row._id}`)}
                        >
                          Price
                        </Button>
                      </TableCell>
                    )} */}
											{reorder && row?.isManual && (
												<>
													<TableCell align="center" style={styleCell}>
														<Button
															variant="outlined"
															onClick={() => navigate(`/add/order/${row._id}`)}
														>
															Reorder
														</Button>
													</TableCell>
													<TableCell align="center" style={styleCell}>
														<Button
															variant="outlined"
															onClick={async () => {
																let result = window.confirm(
																	"Do you want to delete?"
																);
																if (result === true) {
																	await deleteOrder(row);
																	setFilteredRows(
																		filteredRows.filter(
																			(r) => r._id !== row._id
																		)
																	);
																}
															}}
														>
															Delete
														</Button>
													</TableCell>
												</>
											)}
											{msg && (
												<TableCell align="center" style={styleCell}>
													<IconButton
														onClick={() => {
															window.location.href = `https://wa.me/1${row.PhoneNumber}?text=Hi%2C%20Your%20tiffin%20service%20is%20expiring%20today.%20Let%20us%20know%20if%20you%20want%20to%20continue.%20Thank%20you`;
														}}
													>
														<WhatsAppIcon
															fontSize="large"
															style={{ color: "green" }}
														/>
													</IconButton>
												</TableCell>
											)}
											<TableCell style={styleCell}>
												{action === "confirm" ? (
													<Button
														variant="outlined"
														onClick={() =>
															navigate(`/pending/order/${row._id}`)
														}
													>
														Confirm
													</Button>
												) : (
													<OrderDetails
														row={row}
														areas={areas}
														action={action}
														handleSeenMarked={handleSeenMarked}
													/>
												)}
											</TableCell>
										</TableRow>
										<TableRow
											sx={
												(row?.request ||
													row?.price === 0 ||
													!!!_.isEqual(
														row?.selDays?.sort(),
														data?.selDays?.sort()
													)) &&
												{}
											}
										>
											<TableCell
												style={{
													paddingBottom: 0,
													paddingTop: 0,
													justifyContent: "center",
												}}
												colSpan={10}
											>
												{(row?.request ||
													row?.price === 0 ||
													(!!!_.isEqual(
														row?.selDays?.sort(),
														data?.selDays?.sort()
													) &&
														!!row?.selDays)) && (
													<Typography
														variant="body1"
														gutterBottom
														component="div"
														style={{
															marginLeft: 15,
															fontWeight: "bolder",
															flexGrow: 1,
															textAlign: "center",
														}}
													>
														** {row?.request}
														{!!row?.selDays && getDaysNote(row?.selDays)}
														{row?.price === 0 && "(U/P)"} **
													</Typography>
												)}
											</TableCell>
										</TableRow>
										<OrderHistory
											openHistory={openHistory}
											index={index}
											order={row}
											data={row?.history}
											orderDate={row?.date}
											areas={areas}
										/>
									</React.Fragment>
								))}
						</TableBody>
					)}
				</Table>
			</TableContainer>
			{filteredRows.length > 0 && (
				<Grid
					item
					xs={12}
					alignItems="center"
					justifyContent="center"
					container
				>
					<IconButton
						disabled={currentPage === 1}
						onClick={() => {
							let cPage = currentPage - 1;
							setCurrentPage(cPage);
						}}
					>
						<SkipPreviousIcon />
					</IconButton>
					<Typography
						style={{ fontSize: 20 }}
						alignItems="center"
						justifyContent="center"
					>
						{currentPage} of {totalPage}{" "}
					</Typography>
					<IconButton
						disabled={currentPage >= totalPage}
						onClick={() => {
							let cPage = currentPage + 1;
							setCurrentPage(cPage);
						}}
					>
						<SkipNextIcon />
					</IconButton>
				</Grid>
			)}
		</>
	);
}
