import { Button, Grid } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import TableComponent from "./Seller/TableComponent";
import DataContext from "./api/context";
import { getEOrders } from "./api/order";

const Reorder = () => {
  const { user, setLoading } = useContext(DataContext);
  const [orders, setOrders] = useState([]);
  var getDaysBetweenDates = function (startDate, endDate) {
    var now = moment(startDate).clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("YYYY-MM-DD"));
      now.add(1, "days");
    }
    return dates;
  };
  const fetchAllOrders = async () => {
    setLoading(true);
    if (user.SelID) {
      let dateArray = getDaysBetweenDates(
        moment().subtract(150, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      );

      const { data: aorder } = await getEOrders({
        SelID: user.SelID,
        sDate: { $in: dateArray },
      });
      setOrders(aorder);
    }
    setLoading(false);
  };
  useEffect(() => fetchAllOrders(), []);
  return (
    <>
      <Button
        variant="contained"
        style={{ marginBottom: 20 }}
        onClick={() => (window.location.href = "/add/order")}
      >
        New Customer
      </Button>

      <Grid container style={{ paddingRight: 40 }}>
        <TableComponent rows={orders} reorder={true} cspan={9} />{" "}
      </Grid>
    </>
  );
};

export default Reorder;
