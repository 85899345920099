import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { getMenuBySelId, updateMenu } from "./api/menu";
import DataContext from "./api/context";
import ScrollableList from "./ScrollableList";
const Holidays = () => {
  const [addDate, setAddDate] = useState(moment().format("YYYY-MM-DD"));
  const [holidays, setHolidays] = useState([]);
  const [error, setError] = useState();
  const { user } = useContext(DataContext);
  const getHolidays = async () => {
    const { data } = await getMenuBySelId(user.SelID);
    if (data?.holidays) {
      setHolidays(data?.holidays);
    }
  };
  useEffect(() => getHolidays(), [user]);

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {holidays ? (
        <ScrollableList
          heading={"Holidays (" + holidays?.length + ")"}
          items={holidays}
          setAddDate={setAddDate}
        />
      ) : (
        <Typography style={{ fontWeight: "bold" }}>
          No holidays added
        </Typography>
      )}
      <Grid
        item
        xs={12}
        container
        style={{ marginTop: 30 }}
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          id="sDate"
          name="sDate"
          type="date"
          style={{ width: "320px" }}
          variant="outlined"
          value={addDate}
          onChange={(e) => {
            setAddDate(e.target.value);
            setError();
          }}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        {error && (
          <Typography style={{ marginBlock: 10 }} color="red">
            {error}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          style={{
            height: 40,
            marginTop: 20,
            width: 100,
            backgroundColor: "#9F6BA0",
          }}
          onClick={async () => {
            if (moment(addDate).day() === 0 || moment(addDate).day() === 6) {
              setError("Weekends cannot be added");
            } else if (!!holidays.find((ad) => ad === addDate)) {
              setError("Already added");
            } else {
              setHolidays([...holidays, addDate]);
              await updateMenu({
                SelID: user.SelID,
                holidays: [...holidays, addDate],
              });
            }
          }}
        >
          Add
        </Button>
        <Button
          variant="contained"
          color="error"
          style={{ height: 40, marginTop: 20, width: 100, marginLeft: 10 }}
          onClick={async () => {
            if (!holidays.find((ad) => ad === addDate)) {
              setError("Not Found");
            } else {
              await updateMenu({
                SelID: user.SelID,
                holidays: holidays.filter((item) => item !== addDate),
              });
              setHolidays(holidays.filter((item) => item !== addDate));
            }
          }}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};
//
export default Holidays;
