import http from "./httpService";
import api from "./config";
import { encryptParams, encryptPayload } from "./encrypt";

const getArea = async (values) => {
  const { data } = await http.get(
    api + "/area/" + encryptParams(values.toString())
  );
  return data;
};

const updateArea = async (values) => {
  const { data } = await http.post(
    api + "/update/area/",
    encryptPayload(values)
  );
  return data;
};

export { getArea, updateArea };
