import { Grid } from "@mui/material";
import CryptoJS from "crypto-js";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import AppBar from "./AppBar";
import Footer from "./Footer";
import LoginForm from "./LoginForm";
import OpenRoutes from "./OpenRoutes";
import ServiceRoutes from "./Routes";
import DataContext from "./api/context";
import Layout from "./shared/Layout";
import Loading from "./shared/Loading";
const App = () => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState();
  const [side, setSide] = React.useState({
    left: false,
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    localStorage.removeItem("user");
    try {
      if (localStorage.getItem("token")) {
        const decrypted = CryptoJS.AES.decrypt(
          localStorage.getItem("token"),
          process.env.REACT_APP_ENCRYPT_DATA
        );

        // Convert the decrypted data back to a JSON string
        const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

        // Parse the JSON string to get the original object
        const decryptedObject = JSON.parse(decryptedString);

        setUser(decryptedObject);
      }
    } catch (err) {
      localStorage.removeItem("token");
    }
  }, []);
  return (
    <DataContext.Provider
      value={{
        open,
        setOpen,
        user,
        setUser,
        side,
        setSide,
        loading,
        setLoading,
      }}
    >
      {/* <Grid item xs={12}>
        <Typography>Harnish</Typography>
      </Grid> */}
      <SnackbarProvider style={{ fontSize: 20 }} autoHideDuration={2000}>
        {user && <AppBar />}
        <LoginForm />
        <Loading />
        <Grid container style={{ minHeight: "80vh", padding: "8px" }}>
          {user ? (
            <>
              <Layout>
                <Grid Container>
                  <ServiceRoutes />
                </Grid>
              </Layout>
            </>
          ) : (
            <OpenRoutes />
          )}
        </Grid>

        <Footer />
      </SnackbarProvider>
    </DataContext.Provider>
  );
};

export default App;
