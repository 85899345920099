import { FormControlLabel, FormGroup, Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import React from "react";
const DaySelector = ({
  selDays = [1, 2, 3, 4, 5],
  handleChange,
  disabled = false,
}) => {
  const days = [
    { id: 0, name: "Sun" },
    { id: 1, name: "Mon" },
    { id: 2, name: "Tue" },
    { id: 3, name: "Wed" },
    { id: 4, name: "Thu" },
    { id: 5, name: "Fri" },
    { id: 6, name: "Sat" },
  ];

  return (
    <Grid item xs={12}>
      <FormGroup row={true}>
        {days?.length > 0 &&
          days.map((day) => (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  checked={selDays && _.includes(selDays, day.id)}
                />
              }
              label={day.name}
              onChange={(e) => {
                if (e.target.checked) {
                  handleChange("selDays", [...selDays, day.id]);
                } else {
                  handleChange(
                    "selDays",
                    selDays.filter((da) => day.id !== da)
                  );
                }
              }}
            />
          ))}
      </FormGroup>
    </Grid>
  );
};

export default DaySelector;
