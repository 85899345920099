import { Player } from "@lottiefiles/react-lottie-player";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import DataContext from "../api/context";
import {
  getAnySubscriptions,
  getPendingSubscriptions,
  getSubscriptionsBySel,
} from "../api/subscription";
import SubscriptionTableComponent from "./SubscriptionTableComponent";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: "0px", marginTop: "24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Subscriptions() {
  const [value, setValue] = useState(0);
  const [allOrders, setAllOrders] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [phone, setPhone] = useState();
  const [vegActOrders, setVegActOrders] = useState([]);
  const [nvegOrders, setNvegOrders] = useState([]);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [rangeOption, setRangeOption] = React.useState("lastWeek");
  const [pausedOrders, setPausedOrders] = useState([]);
  const [expOrders, setExpOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [areas, setAreas] = useState([]);
  const [activeDate, setActiveDate] = useState(moment().format("YYYY-MM-DD"));
  const [phoneOrder, setPhoneOrder] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const { user, setLoading } = useContext(DataContext);
  const today = moment().format("YYYY-MM-DD");
  const { search } = useLocation();
  const [expDate, setExpDate] = useState(today);
  var getDaysBetweenDates = function (startDate, endDate) {
    var now = moment(startDate).clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("YYYY-MM-DD"));
      now.add(1, "days");
    }
    return dates;
  };
  const fetchOrders = async () => {
    setLoading(true);

    const today = new Date();
    if (user.SelID) {
      const data = await getSubscriptionsBySel({
        SelID: parseInt(user.SelID),
        isPaid: true,
        status: "active",
        // renewalDateFrom: moment().subtract(10, "days").format("YYYY-MM-DD"),
        // renewalDateTo: moment().format("YYYY-MM-DD"),
      });
      setSubscriptions(data);
      const pendOrders = await getPendingSubscriptions({
        SelID: user.SelID,
      });
      setPendingOrders(pendOrders);
      const pOrders = await getSubscriptionsBySel({
        SelID: user.SelID,
        status: "active",
        isPaid: true,
      });
      const today = moment().format("YYYY-MM-DD");
      const isPausedToday = (pause) =>
        moment(today, "YYYY-MM-DD").isSameOrAfter(
          moment(pause.start, "YYYY-MM-DD"),
          "day"
        ) &&
        moment(today, "YYYY-MM-DD").isSameOrBefore(
          moment(pause.end, "YYYY-MM-DD"),
          "day"
        );

      setPausedOrders(
        pOrders.filter(
          (order) => order.pause || order.pauses.some(isPausedToday)
        )
      );
      let dateArray = getDaysBetweenDates(
        startDate,
        moment().format("YYYY-MM-DD")
      );

      const canceledSubs = await getAnySubscriptions({
        SelID: parseInt(user.SelID),
        isPaid: true,
        status: "canceled",
      });

      setAllOrders(canceledSubs);
    }
    setLoading(false);
  };
  const fetchExpOrders = async () => {
    let dateArray = [];

    if (user.SelID) {
      const data = await getSubscriptionsBySel({
        SelID: user.SelID,
        isPaid: true,
        status: "active",
        renewalDate: expDate,
        cancel_at_period_end: true,
      });
      setExpOrders(data);
    }
  };
  useEffect(
    () => setValue(parseInt(searchParams.get("tabInd")) || 0),
    [search]
  );
  useEffect(() => {
    if (
      isNaN(parseInt(searchParams.get("tabInd"))) ||
      parseInt(searchParams.get("tabInd")) < 0 ||
      parseInt(searchParams.get("tabInd")) > 5
    ) {
      setSearchParams({ tabInd: 0 });
    }
    fetchOrders();
  }, [user, activeDate, startDate]);
  useEffect(() => {
    fetchExpOrders();
  }, [user, expDate, rangeOption]);
  const fetchHistory = async () => {
    if (user.SelID && phone) {
      const data = await getAnySubscriptions({
        SelID: user.SelID,
        PhoneNumber: phone,
      });

      setPhoneOrder(data);
    }
  };
  const handleChange = (event, newValue) => {
    setSearchParams({ tabInd: newValue });
    setValue(newValue);
  };

  return (
    <>
      <Grid item xs={12} container style={{ padding: 20, paddingTop: 0 }}>
        <Typography variant="h4" fontWeight="bold">
          Subscriptions
        </Typography>
      </Grid>
      <Box sx={{ width: "100%" }} style={{ paddingRight: 40 }}>
        <Box sx={{ borderBottom: 1, borderColor: "#9f6ba0", padding: "0px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            indicatorColor="secondary"
            allowScrollButtonsMobile
            style={{ padding: "0px", color: "#9f6ba0" }}
            aria-label="basic tabs example"
          >
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="Pending Subscriptions"
              {...a11yProps(0)}
            />
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="Active Subscriptions"
              {...a11yProps(1)}
            />
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="Subscriptions History"
              {...a11yProps(2)}
            />
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="Expiring Subscriptions"
              {...a11yProps(3)}
            />
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="Paused Subscriptions"
              {...a11yProps(4)}
            />
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="Canceled Subscriptions"
              {...a11yProps(5)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} style={{ color: "#9f6ba0" }}>
          <SubscriptionTableComponent
            rows={pendingOrders}
            action="confirm"
            cspan={8}
            activeDate={activeDate}
            setActiveDate={setActiveDate}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SubscriptionTableComponent
            rows={subscriptions}
            action="details"
            cspan={8}
            activeDate={activeDate}
            setActiveDate={setActiveDate}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
              <InputLabel htmlFor="phone">Phone Number</InputLabel>
              <OutlinedInput
                id="phone"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">+1</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      aria-label="toggle password visibility"
                      onClick={fetchHistory}
                      edge="end"
                    >
                      Search
                    </Button>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Grid>
          {phoneOrder.length > 0 ? (
            <SubscriptionTableComponent
              rows={phoneOrder}
              search={true}
              action="adjust"
            />
          ) : (
            <Player
              src="https://assets7.lottiefiles.com/private_files/lf30_cgfdhxgx.json"
              className="player"
              loop
              autoplay
              style={{ height: "300px", width: "300px" }}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {/* <Grid item xs={12} style={{ marginBottom: 10 }}>
            <FormControl fullWidth style={{ minWidth: 50 }}>
              <InputLabel id="rangeOption">Select Range</InputLabel>
              <Select
                labelId="rangeOption"
                id="rangeOption"
                value={rangeOption}
                label="Select Range"
                onChange={(e) => {
                  setRangeOption(e.target.value);
                }}
              >
                <MenuItem value="lastWeek">Last Week</MenuItem>
                <MenuItem value="lastMonth">Last Month</MenuItem>
                <MenuItem value="specificDate">Specific Date</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* {rangeOption === "specificDate" && ( */}
          <Grid style={{ marginBottom: 10 }}>
            <TextField
              style={{ marginBlock: 10 }}
              id="expDate"
              name="expDate"
              type="date"
              fullWidth
              variant="outlined"
              value={expDate}
              onChange={(e) => setExpDate(e.target.value)}
            />
          </Grid>
          {/* )} */}
          <SubscriptionTableComponent rows={expOrders} msg={true} cspan={9} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <SubscriptionTableComponent
            rows={pausedOrders}
            action="paused"
            cspan={8}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <SubscriptionTableComponent
            rows={allOrders}
            action="adjust"
            cspan={8}
          />
        </TabPanel>
      </Box>
    </>
  );
}
