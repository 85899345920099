import React, { useState, useContext, useEffect } from "react";
import { TextField, Button, Grid, Paper } from "@mui/material";
import { getMenuBySelId, updateMenu } from "./api/menu";
import DataContext from "./api/context";
import { useSnackbar } from "notistack";
const WeeklyMenu = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [menu, setMenu] = useState({
    Monday: "",
    Tuesday: "",
    Wednesday: "",
    Thursday: "",
    Friday: "",
    Saturday: "",
    Sunday: "",
  });
  const { user } = useContext(DataContext);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleChange = (day, value) => {
    setMenu((prevMenu) => ({
      ...prevMenu,
      [day]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await updateMenu({
      SelID: user.SelID,
      weeklyMenu: { menu: menu, startDate: startDate, endDate: endDate },
    });
    enqueueSnackbar("Updated your menu deliciously!");
    // You can perform further actions here, such as sending the menu data to a backend server
  };
  const fetchMenu = async () => {
    const { data } = await getMenuBySelId(user.SelID);
    const {
      weeklyMenu: { menu, startDate, endDate },
    } = data;
    setMenu(menu);
    setStartDate(startDate);
    setEndDate(endDate);
  };
  useEffect(() => {
    fetchMenu();
  }, []);
  const today = new Date().toISOString().split("T")[0];
  return (
    <>
      <Grid
        item
        xs={12}
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper style={styles.scrollableList}>
          <h2 style={{ paddingLeft: 20, marginBottom: 30, color: "#9F6BA0" }}>
            Weekly Menu
          </h2>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {Object.keys(menu).map((day) => (
                <Grid
                  item
                  xs={12}
                  key={day}
                  style={{ justifyContent: "center" }}
                  container
                >
                  <TextField
                    style={{ maxWidth: 500 }}
                    label={day}
                    variant="outlined"
                    value={menu[day]}
                    onChange={(e) => handleChange(day, e.target.value)}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              container
              style={{ justifyContent: "center", marginTop: 20 }}
            >
              <TextField
                type="date"
                label="From"
                value={startDate}
                onChange={handleStartDateChange}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                style={{ marginRight: "16px" }}
                inputProps={{ min: today }}
              />

              <TextField
                type="date"
                label="To"
                value={endDate}
                onChange={handleEndDateChange}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                style={{ marginRight: "16px" }}
                inputProps={{ min: today }}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 40, justifyContent: "center" }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  marginLeft: 10,
                  marginBottom: 20,
                  backgroundColor: "#9F6BA0",
                }}
              >
                Update menu
              </Button>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </>
  );
};

export default WeeklyMenu;

const styles = {
  scrollableList: {
    height: "500px",
    maxWidth: "550px",
    minWidth: "300px",
    overflowY: "scroll",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
};
