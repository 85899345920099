import React, { useContext, useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import DataContext from "./api/context";
import { saveStripeAccessKey } from "./api/seller";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StripeAuthorize = () => {
  const navigate = useNavigate();
  const { user, setLoading } = useContext(DataContext);
  const [authStatus, setAuthStatus] = useState();
  const [searchParams] = useSearchParams();
  const postAccessToken = async (code) => {
    try {
      if (user?.SelID) {
        await saveStripeAccessKey({
          authorization_code: code,
          SelID: user.SelID,
        });
        setAuthStatus("Authorized 🎉");
        setTimeout(() => {}, 3000); // Delay in milliseconds
        setLoading(false);
        window.location.href = "/";
      }
    } catch (err) {
      setAuthStatus("Something went wrong 😔");
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    const code = searchParams.get("code"); // "testCode"
    postAccessToken(code);
  }, [user?.SelID]);
  return (
    <>
      <Grid
        item
        xs={12}
        container
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: 50,
        }}
      >
        <Typography variant="h4">{authStatus}</Typography>
      </Grid>
      {authStatus === "Something went wrong 😔" && (
        <Grid
          item
          xs={12}
          container
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Button href="tel:+1-613-869-5322">Contact us</Button>
          <Button onClick={() => navigate("/")}>Go to home</Button>
        </Grid>
      )}
    </>
  );
};

export default StripeAuthorize;
