import { Player } from "@lottiefiles/react-lottie-player";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import DataContext from "../api/context";
import {
  getActiveOrders,
  getActivePauseOrders,
  getEOrders,
  getOrders,
  getPendingOrders,
} from "../api/order";
import TableComponent from "./TableComponent";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: "0px", marginTop: "24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Orders() {
  const [value, setValue] = useState(0);
  const [allOrders, setAllOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [phone, setPhone] = useState();
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [rangeOption, setRangeOption] = React.useState("lastWeek");
  const [startRange, setStartRange] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [endRange, setEndRange] = React.useState(moment().format("YYYY-MM-DD"));
  const [pausedOrders, setPausedOrders] = useState([]);
  const [expOrders, setExpOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [areas, setAreas] = useState([]);
  const [activeDate, setActiveDate] = useState(moment().format("YYYY-MM-DD"));
  const [phoneOrder, setPhoneOrder] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [excludePaused, setExcludePaused] = useState(true);
  const [uniqueCus, setUniqueCus] = useState(true);
  const [onlyToday, setOnlyToday] = useState(true);
  const { user, setLoading } = useContext(DataContext);
  const today = moment().format("YYYY-MM-DD");
  const { search } = useLocation();
  const [expDate, setExpDate] = useState(today);
  var getDaysBetweenDates = function (startDate, endDate) {
    var now = moment(startDate).clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("YYYY-MM-DD"));
      now.add(1, "days");
    }
    return dates;
  };
  const fetchOrders = async () => {
    setLoading(true);

    const today = new Date();
    if (user.SelID) {
      const data = await getActiveOrders(
        {
          SelID: user.SelID,
        },
        activeDate,
        onlyToday,
        excludePaused
      );
      setOrders(data);
      const pendOrders = await getPendingOrders({
        SelID: user.SelID,
      });
      setPendingOrders(pendOrders);
      const { data: pOrders } = await getEOrders({
        SelID: user.SelID,
        pause: true,
      });
      let pausesOrder = await getActivePauseOrders(
        {
          SelID: user.SelID,
        },
        moment().format("YYYY-MM-DD"),
        false
      );
      pausesOrder = [...pausesOrder, ...pOrders];
      setPausedOrders(pausesOrder);
      let dateArray = getDaysBetweenDates(
        startDate,
        moment().format("YYYY-MM-DD")
      );
      if (startDate) {
        const { data: aorder } = await getEOrders({
          SelID: user.SelID,
          sDate: { $in: dateArray },
        });
        const uniqueOrders = aorder.reduce((acc, current) => {
          const x = acc.find(
            (order) => order.PhoneNumber === current.PhoneNumber
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        const alOrders = uniqueCus ? uniqueOrders : aorder;
        setAllOrders(alOrders);
      }
    }
    setLoading(false);
  };
  const fetchExpOrders = async () => {
    if (user.SelID && startRange && endRange) {
      const data = await getOrders({
        SelID: user.SelID,
        calEndDateRange: { startRange: startRange, endRange: endRange },
      });
      setExpOrders(data);
    }
  };
  useEffect(
    () => setValue(parseInt(searchParams.get("tabInd")) || 0),
    [search]
  );
  useEffect(() => {
    if (
      isNaN(parseInt(searchParams.get("tabInd"))) ||
      parseInt(searchParams.get("tabInd")) < 0 ||
      parseInt(searchParams.get("tabInd")) > 5
    ) {
      setSearchParams({ tabInd: 0 });
    }
    fetchOrders();
  }, [user, activeDate, startDate, excludePaused, onlyToday, uniqueCus]);
  useEffect(() => {
    fetchExpOrders();
  }, [user, startRange, endRange]);
  const fetchHistory = async () => {
    if (user.SelID && phone) {
      const { data: orders } = await getEOrders({
        SelID: user.SelID,
        PhoneNumber: phone,
      });
      setPhoneOrder(orders);
    }
  };
  const handleChange = (event, newValue) => {
    setSearchParams({ tabInd: newValue });
    setValue(newValue);
  };

  return (
    <>
      <Grid item xs={12} container style={{ padding: 20, paddingTop: 0 }}>
        <Typography variant="h4" fontWeight="bold">
          Orders
        </Typography>
      </Grid>
      <Box sx={{ width: "100%" }} style={{ paddingRight: 40 }}>
        <Box sx={{ borderBottom: 1, borderColor: "#9f6ba0", padding: "0px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            indicatorColor="secondary"
            allowScrollButtonsMobile
            style={{ padding: "0px", color: "#9f6ba0" }}
            aria-label="basic tabs example"
          >
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="Pending Orders"
              {...a11yProps(0)}
            />
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="Active Orders"
              {...a11yProps(1)}
            />
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="Order History"
              {...a11yProps(2)}
            />
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="Expiring Orders"
              {...a11yProps(3)}
            />
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="Paused"
              {...a11yProps(4)}
            />
            <Tab
              style={{ color: "#9f6ba0", fontWeight: "bold" }}
              label="All Orders"
              {...a11yProps(5)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} style={{ color: "#9f6ba0" }}>
          <TableComponent
            rows={pendingOrders}
            action="confirm"
            cspan={8}
            activeDate={activeDate}
            setActiveDate={setActiveDate}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableComponent
            rows={orders}
            action="details"
            cspan={8}
            activeDate={activeDate}
            setActiveDate={setActiveDate}
            excludePaused={excludePaused}
            setExcludePaused={setExcludePaused}
            onlyToday={onlyToday}
            setOnlyToday={setOnlyToday}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
              <InputLabel htmlFor="phone">Phone Number</InputLabel>
              <OutlinedInput
                id="phone"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">+1</InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      aria-label="toggle password visibility"
                      onClick={fetchHistory}
                      edge="end"
                    >
                      Search
                    </Button>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {phoneOrder.length > 0 ? (
            <TableComponent rows={phoneOrder} search={true} />
          ) : (
            <Player
              src="https://assets7.lottiefiles.com/private_files/lf30_cgfdhxgx.json"
              className="player"
              loop
              autoplay
              style={{ height: "300px", width: "300px" }}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid style={{ marginBottom: 10 }} item xs={12} container>
            <Grid
              style={{ marginBottom: 10, paddingInline: 10 }}
              item
              xs={12}
              md={6}
              container
            >
              <TextField
                fullWidth
                style={{ marginBlock: 10 }}
                label="Start Range"
                id="startRange"
                name="startRange"
                type="date"
                variant="outlined"
                value={startRange}
                onChange={(e) => setStartRange(e.target.value)}
              />
            </Grid>
            <Grid
              style={{ marginBottom: 10, paddingInline: 10 }}
              item
              xs={12}
              md={6}
              container
            >
              <TextField
                fullWidth
                style={{ marginBlock: 10 }}
                label="End Range"
                id="endRange"
                name="endRange"
                type="date"
                variant="outlined"
                value={endRange}
                onChange={(e) => setEndRange(e.target.value)}
              />
            </Grid>
          </Grid>

          <TableComponent rows={expOrders} msg={true} cspan={9} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <TableComponent rows={pausedOrders} action="paused" cspan={8} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <TableComponent
            rows={allOrders}
            action="adjust"
            cspan={8}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            uniqueCus={uniqueCus}
            setUniqueCus={setUniqueCus}
            setActiveDate={setActiveDate}
          />
        </TabPanel>
      </Box>
    </>
  );
}
