import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import * as React from "react";
import { useContext, useEffect } from "react";
import DataContext from "../api/context";
import { getMenuBySelId } from "../api/menu";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SubscriptionDetails({
  row,
  action,
  handleSeenMarked,
  areas,
}) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState();
  const [holidays, setHolidays] = React.useState([]);
  const { user } = useContext(DataContext);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const getMenu = async () => {
    const { data } = await getMenuBySelId(user.SelID);
    setData(data);
  };
  const getHolidays = async () => {
    if (data?.holidays) {
      setHolidays(data?.holidays);
    }
  };
  const areaConfig = areas && areas.find((area) => area?.id === row.driver);
  useEffect(() => getHolidays(), [user]);
  const handleClose = () => {
    setOpen(false);
  };
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const getDaysNote = (selDays = []) => {
    let daysNote = " ";
    if (_.isEqual(selDays?.sort(), data?.selDays?.sort())) {
      return daysNote;
    } else {
      daysNote += " ( ";
      selDays && selDays?.sort();
      selDays.map((id) => {
        daysNote = daysNote + days[id] + " ";
      });
      daysNote += " ) ";
      return daysNote;
    }
  };
  useEffect(() => {
    getMenu();
  }, [user]);
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Details
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Subscription Details
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {row?.FirstName + " "}
            {row?.LastName}
          </Typography>
          <Typography gutterBottom>{row?.PhoneNumber}</Typography>
          {/* <Typography variant="h5" align="center" gutterBottom>
            Last Order
          </Typography> */}
          <Typography gutterBottom>{row?.serviceOpt}</Typography>
          <Typography gutterBottom>{row?.menuOpt}</Typography>
          <Typography gutterBottom>{row?.rrOpt}</Typography>
          <Typography gutterBottom>{row?.rsOpt}</Typography>
          <Typography gutterBottom>{areaConfig?.aname}</Typography>
          <Typography gutterBottom>
            Ordered on - {new Date(row?.date).toDateString()}
          </Typography>
          <Typography gutterBottom>Status - {row?.status}</Typography>
          <Typography gutterBottom>Amount Paid - CA${row?.price}</Typography>
          <Typography style={{ fontWeight: "bolder" }} gutterBottom>
            **{row?.request}
            {getDaysNote(row?.selDays)}
            {row?.price === 0 && "(U/P)"}**
          </Typography>
          {row?.note && (
            <Typography style={{ fontWeight: "bolder" }} gutterBottom>
              Note - {row?.note}
            </Typography>
          )}
        </DialogContent>
        {action === "new" && (
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleClose();
                handleSeenMarked(row._id);
              }}
            >
              Mark As Seen
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
}
