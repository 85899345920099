import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";

function DatePickerComponent({
  pauseStartDate,
  setPauseStartDate,
  pauseEndDate,
  setPauseEndDate,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{ margin: "20px" }}>
        <DesktopDatePicker
          label="Start Date"
          inputFormat="yyyy-MM-dd"
          value={pauseStartDate}
          onChange={(newValue) => {
            setPauseStartDate(newValue);
            // Ensure the end date is not before the start date
            if (pauseEndDate && newValue && newValue > pauseEndDate) {
              setPauseEndDate(newValue); // Set end date same as start date if it falls behind
            }
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
      <div style={{ margin: "20px" }}>
        <DesktopDatePicker
          label="End Date"
          inputFormat="yyyy-MM-dd"
          value={pauseEndDate}
          minDate={pauseStartDate} // Allow the same day as start date
          onChange={setPauseEndDate}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
    </LocalizationProvider>
  );
}

export default DatePickerComponent;
