import React, { useEffect, useState, useContext } from "react";
import DataContext from "./api/context";
import { getEOrders } from "./api/order";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment from "moment";
import { Grid, Typography } from "@mui/material";
const EarningsCalculator = () => {
  const { user } = useContext(DataContext);
  const [month, setMonth] = React.useState("");
  const [num, setNum] = useState(0);
  const [earn, setEarn] = useState(0);
  const handleChange = (event) => {
    setMonth(event.target.value);
  };

  const fetchAllOrders = async () => {
    const { data: orders } = await getEOrders({
      SelID: user.SelID,
    });
    let total = 0;
    let orderNum = 0;
    orders.map((order) => {
      var oneDate = moment(order.tDate, "YYYY-MM-DD");
      var monthName = oneDate.format("MM");
      if (monthName === month.toString()) {
        total += parseFloat(order.price);
        orderNum++;
      }
    });
    setNum(orderNum);
    setEarn(total);
  };
  useEffect(() => {
    fetchAllOrders();
  }, [user, month]);
  return (
    <>
      <Grid item xs={12} style={{ marginBottom: 40 }}>
        <Typography style={{ fontSize: 30 }}>Earnings Calculator</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Month</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={month}
              label="Month"
              onChange={handleChange}
            >
              <MenuItem value="01">Jan</MenuItem>
              <MenuItem value="02">Feb</MenuItem>
              <MenuItem value="03">Mar</MenuItem>
              <MenuItem value="04">Apr</MenuItem>
              <MenuItem value="05">May</MenuItem>
              <MenuItem value="06">Jun</MenuItem>
              <MenuItem value="07">Jul</MenuItem>
              <MenuItem value="08">Aug</MenuItem>
              <MenuItem value="09">Sep</MenuItem>
              <MenuItem value="10">Oct</MenuItem>
              <MenuItem value="11">Nov</MenuItem>
              <MenuItem value="12">Dec</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <Typography style={{ fontSize: 20 }}>
          Number of Orders - {num}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <Typography style={{ fontSize: 20 }}>Earnings - {earn}</Typography>
      </Grid>
    </>
  );
};

export default EarningsCalculator;
