import http from "./httpService";
import api from "./config";
import { encryptParams, encryptPayload } from "./encrypt";

const getRoute = async (values) => {
  const { data } = await http.get(api + "/route/" + encryptParams(values));
  return data;
};
const createRoute = async (values) => {
  const { data } = await http.post(
    api + "/create/route/",
    encryptPayload(values)
  );
  return data;
};
const getRouteBySel = async (values) => {
  const { data } = await http.get(
    api + "/routes/seller/" + encryptParams(values)
  );
  return data;
};
const getRouteBySelToday = async (values) => {
  const { data } = await http.post(
    api + "/route/today/",
    encryptPayload(values)
  );
  return data;
};
const getAllRouteBySel = async (values) => {
  const { data } = await http.post(api + "/route/all/", encryptPayload(values));
  return data;
};
const updateRoute = async (values) => {
  const { data } = await http.post(
    api + "/update/route",
    encryptPayload(values)
  );
  return data;
};

export {
  getRoute,
  updateRoute,
  getRouteBySel,
  createRoute,
  getRouteBySelToday,
  getAllRouteBySel,
};
