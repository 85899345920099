import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import Home from "../Home";
import DataContext from "../api/context";
import { getAnalytics } from "../api/menu";
import { LineChart } from "./LineChart";

const SellerDash = () => {
  const { user } = useContext(DataContext);
  const [sortType, setSortType] = React.useState("orderDate");
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [priceArray, setPriceArray] = React.useState([]);
  const [countArray, setCountArray] = React.useState([]);
  const [toDate, setToDate] = React.useState(
    moment().add(7, "days").format("YYYY-MM-DD")
  );
  const graphCalculations = (calcName, arr) => {
    let value = 0;
    switch (calcName) {
      case "basicAverage":
        if (arr.length === 0) {
          return 0; // Handle the case of an empty array to avoid division by zero.
        }

        let avSum = 0;
        for (let i = 0; i < arr.length; i++) {
          avSum += arr[i];
        }

        const average = avSum / arr.length;
        value = average;
        break;
      case "basicAddition":
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
          sum += arr[i];
        }
        value = sum;
        break;
      default:
        value = 0;
    }
    return value;
  };
  const showDifferentCalculations = () => {};
  const [dateGroupedData, setDateGroupedData] = React.useState([]);
  const [chartLabels, setChartLabels] = React.useState([]);
  const getAllDates = (start, end) => {
    let datesArray = [];

    let startDate = moment(start);
    let endDate = moment(end);
    while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
      datesArray.push(startDate.format("YYYY-MM-DD")); // Add the date in the desired format
      startDate.add(1, "days"); // Increment the date by 1 day
    }

    return datesArray;
  };
  const getGraphData = async () => {
    const { data } = await getAnalytics({
      from: fromDate,
      to: moment(toDate).add(1, "days").format("YYYY-MM-DD"),
      SelID: user.SelID,
      type: sortType,
    });
    let groupedData = {};
    let datesArray = [];
    let salesArray = [];
    if (sortType !== "active") {
      const datesBetween = getAllDates(fromDate, toDate);
      datesBetween.forEach(function (date) {
        groupedData[date] = [];
      });
      datesArray = datesBetween;
      data.forEach((item) => {
        let category;
        if (sortType === "startDate") {
          category = item.sDate;
        } else if (sortType === "endDate") {
          category = item.calEndDate;
        } else if (sortType === "orderDate") {
          category = item.tDate;
        }
        if (!groupedData[category]) {
          groupedData[category] = []; // Initialize an empty array for the category if it doesn't exist
        }
        groupedData[category].push(item); // Push the item to the corresponding category
      });
    } else {
      const datesBetween = getAllDates(fromDate, toDate);
      datesBetween.forEach(function (date) {
        groupedData[date] = [];
      });
      datesArray = datesBetween;
      data.forEach((item) => {
        let activeDates = getAllDates(item.sDate, item.calEndDate);
        activeDates.map((aDate) => {
          if (groupedData[aDate]) {
            groupedData[aDate].push(item);
          }
        });
      });
    }
    setDateGroupedData(groupedData);
    setChartLabels(datesArray);
    setCountArray(Object.values(groupedData).map((item) => item.length));
    setPriceArray(
      Object.values(groupedData).map((item) =>
        item.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.price),
          0
        )
      )
    );
  };
  useEffect(() => {
    getGraphData();
  }, [sortType, fromDate, toDate, user]);
  return (
    <>
      <Home />
      <Grid item md={12} container style={{ padding: 20, paddingTop: 0 }}>
        <Typography variant="h4" fontWeight="bold">
          Dashboard
        </Typography>
      </Grid>
      <Grid item md={12} container>
        <Grid item lg={12} md={12} xs={12} container style={{ padding: 10 }}>
          <Paper
            style={{ width: "100%", padding: 20, minHeight: "300px" }}
            elevation={3}
          >
            <Grid item xs={12} container>
              <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                Sales Analytics
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              style={{ marginBottom: 40 }}
            >
              <Typography style={{ fontSize: 20, marginRight: 10 }}>
                Orders
              </Typography>
              <FormControl variant="standard">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortType}
                  onChange={(e) => setSortType(e.target.value)}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="startDate">Starting</MenuItem>
                  <MenuItem value="orderDate">Ordered</MenuItem>
                  <MenuItem value="endDate">Ending</MenuItem>
                </Select>
              </FormControl>
              <Typography style={{ fontSize: 20, marginInline: 10 }}>
                From
              </Typography>
              <TextField
                name="from"
                type="date"
                variant="standard"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <Typography style={{ fontSize: 20, marginInline: 10 }}>
                To
              </Typography>
              <TextField
                name="to"
                type="date"
                variant="standard"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} container>
              <LineChart
                valuesArray={countArray}
                chartLabels={chartLabels}
                prd="orders"
              />
            </Grid>
          </Paper>
          <Grid item xs={12} container>
            <Paper
              style={{
                width: "100%",
                padding: 20,
                minHeight: "300px",
                marginTop: 5,
              }}
              elevation={3}
            >
              <LineChart
                valuesArray={priceArray}
                chartLabels={chartLabels}
                prd="revenue"
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12} container style={{ padding: 5 }}>
          <Grid item xs={6} container>
            <Paper
              style={{
                width: "100%",
                padding: 20,
                paddingBottom: 0,
                marginBlock: 5,
              }}
              elevation={3}
            >
              <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                Orders
              </Typography>
              <Grid item xs={12} container direction="row">
                {sortType !== "active" && (
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    container
                    style={{ paddingBlock: 50 }}
                  >
                    <Grid item xs={12} style={{ paddingLeft: 10 }}>
                      <Typography style={{ fontSize: 30 }}>Total</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ paddingLeft: 10 }}>
                      <Typography
                        style={{
                          fontSize: 20,
                          lineHeight: "45px",
                          color: "#9f6ba0",
                        }}
                      >
                        {new Intl.NumberFormat("en-US").format(
                          graphCalculations("basicAddition", countArray)
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  lg={6}
                  container
                  style={{ paddingBlock: 50 }}
                >
                  <Grid item xs={12} style={{ paddingLeft: 10 }}>
                    <Typography style={{ fontSize: 30 }}>Average</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ paddingLeft: 10 }}>
                    <Typography
                      style={{
                        fontSize: 20,
                        lineHeight: "45px",
                        color: "#9f6ba0",
                      }}
                    >
                      {new Intl.NumberFormat("en-US").format(
                        Math.round(
                          graphCalculations("basicAverage", countArray)
                        )
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6} container style={{ padding: 5 }}>
            <Paper style={{ width: "100%", padding: 20 }} elevation={3}>
              <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
                Revenue
              </Typography>
              <Grid item xs={12} container direction="row">
                {sortType !== "active" && (
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    container
                    style={{ paddingBlock: 50 }}
                  >
                    <Grid item xs={12} style={{ paddingLeft: 10 }}>
                      <Typography style={{ fontSize: 30 }}>Total</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ paddingLeft: 10 }}>
                      <Typography
                        style={{
                          fontSize: 20,
                          lineHeight: "45px",
                          color: "#3fc060",
                        }}
                      >
                        {new Intl.NumberFormat("en-CA", {
                          style: "currency",
                          currency: "CAD",
                        }).format(
                          graphCalculations("basicAddition", priceArray)
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  lg={6}
                  container
                  style={{ paddingBlock: 50 }}
                >
                  <Grid item xs={12} style={{ paddingLeft: 10 }}>
                    <Typography style={{ fontSize: 30 }}>Average</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ paddingLeft: 10 }}>
                    <Typography
                      style={{
                        fontSize: 20,
                        lineHeight: "45px",
                        color: "#3fc060",
                      }}
                    >
                      {new Intl.NumberFormat("en-CA", {
                        style: "currency",
                        currency: "CAD",
                      }).format(graphCalculations("basicAverage", priceArray))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SellerDash;
