import { Typography } from "@mui/material";
import React, { useState } from "react";

const ScrollableList = ({ heading, items, setAddDate }) => {
  return (
    <div style={styles.scrollableList}>
      <h2 style={{ marginLeft: 10, color: "#9F6BA0" }}>
        {heading ? heading : ""}
      </h2>

      <body style={{ marginLeft: 10, marginBottom: 20 }}>Select to choose</body>
      <div style={styles.listContainer}>
        {items?.length > 0 ? (
          <>
            <ul style={styles.list}>
              {items.map((item, index) => (
                <li
                  key={index}
                  style={styles.listItem}
                  onClick={() => setAddDate(item)}
                >
                  <div>
                    <Typography>{item}</Typography>
                  </div>
                  {/* <div style={styles.secondaryText}>
                {item?.description ? item?.description : "No description added"}
              </div> */}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <div style={{ paddingLeft: 10 }}>
            <Typography variant="body2">No holidays added</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  scrollableList: {
    height: "500px",
    maxWidth: "500px",
    minWidth: "300px",
    overflowY: "scroll",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  listContainer: {
    maxWidth: "300px",
    margin: "auto",
  },
  list: {
    listStyle: "none",
    padding: "0",
    margin: "0",
  },
  listItem: {
    padding: "12px",
    borderBottom: "1px solid #eee",
    fontSize: "16px",
    cursor: "pointer",
  },
  secondaryText: {
    color: "#666",
    fontSize: "14px",
    marginTop: "4px",
  },
};

export default ScrollableList;
