import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { getMenuBySelId, updateMenu } from "./api/menu";
import DataContext from "./api/context";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Paper,
} from "@mui/material";
import { useSnackbar } from "notistack";
const PostalCodeManager = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [postalCodes, setPostalCodes] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selPlans, setSelPlans] = useState([]);
  const [selectedPostalConfig, setSelectedPostalConfig] = useState();
  const [postalConfig, setPostalConfig] = useState({});
  const [selectedPostalDeliveryFees, setSelectedPostalDeliveryFees] = useState(
    {}
  );
  const [postalCodeError, setPostalCodeError] = useState();
  const { user } = useContext(DataContext);
  useEffect(() => {
    const getPostalCodes = async () => {
      const {
        data: { delArea, plansConfig = {}, postalConfig: postalConf },
      } = await getMenuBySelId(user.SelID);
      setPostalCodes(delArea);
      setSelPlans(Object.keys(plansConfig));
      setPostalConfig(postalConf);
    };

    getPostalCodes();
  }, [user]);
  const handleInputChange = (e) => {
    setInputValue(e.target.value.toUpperCase());
  };

  const addPostalCode = async () => {
    const pattern = /^[A-Z]\d[A-Z]$/;
    if (inputValue && pattern.test(inputValue)) {
      setPostalCodeError(false);
      setPostalCodes([...postalCodes, inputValue]);
      await updateMenu({
        SelID: user.SelID,
        delArea: [...postalCodes, inputValue],
      });
      setInputValue("");
      enqueueSnackbar(
        "Freshly seasoned, the postal code has been updated to perfection."
      );
    } else {
      setPostalCodeError(true);
    }
  };

  const deletePostalCode = async (code) => {
    const delPostalCodes = postalCodes.filter((c) => c !== code);
    setPostalCodes(delPostalCodes);
    await updateMenu({
      SelID: user.SelID,
      delArea: delPostalCodes,
    });
    if (postalConfig.hasOwnProperty(code)) {
      let newPostalConfig = postalConfig;
      delete newPostalConfig[code];
      setPostalConfig(newPostalConfig);
      await updateMenu({
        SelID: user.SelID,
        postalConfig: newPostalConfig,
      });
    }
    enqueueSnackbar(
      "Freshly seasoned, the postal code has been updated to perfection."
    );
  };
  const showDelText = (fees) => {
    if (
      !fees ||
      Object.keys(fees).length === 0 ||
      Object.values(fees).filter((item) => item.trim() !== "").length === 0
    ) {
      return <Typography>Default</Typography>;
    }
    return (
      <>
        {Object.entries(fees).map(([key, value]) => (
          <>
            {value && (
              <Typography>
                {key} - {value}
              </Typography>
            )}
          </>
        ))}
      </>
    );
  };
  return (
    <>
      <Dialog open={open}>
        <DialogTitle style={{ color: "#9F6BA0" }}>
          {selectedPostalConfig}
        </DialogTitle>
        <DialogContent>
          <Typography style={{ color: "#9F6BA0", marginBottom: 10 }}>
            Delivery Fees
          </Typography>
          {selPlans.length > 0
            ? selPlans.map((plan) => (
                <>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="deliveryFees"
                    name="deliveryFees"
                    label={plan + " (Optional)"}
                    type="text"
                    value={
                      selectedPostalDeliveryFees.hasOwnProperty(plan)
                        ? selectedPostalDeliveryFees[plan]
                        : ""
                    }
                    fullWidth
                    onChange={(e) => {
                      setSelectedPostalDeliveryFees({
                        ...selectedPostalDeliveryFees,
                        [plan]: e.target.value,
                      });
                    }}
                    style={{ color: "#9F6BA0" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">C$</InputAdornment>
                      ),
                    }}
                  />
                </>
              ))
            : "Please add plans!"}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setPostalConfig({
                ...postalConfig,
                ...{
                  [selectedPostalConfig]: {
                    deliveryFees: selectedPostalDeliveryFees,
                  },
                },
              });
              await updateMenu({
                SelID: user.SelID,
                postalConfig: {
                  ...postalConfig,
                  ...{
                    [selectedPostalConfig]: {
                      deliveryFees: selectedPostalDeliveryFees,
                    },
                  },
                },
              });
              setOpen(false);
              enqueueSnackbar(
                "Freshly seasoned, the postal code has been updated to perfection."
              );
            }}
          >
            Save
          </Button>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Grid
        item
        xs={12}
        container
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Grid
          item
          xs={12}
          container
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper style={styles.scrollableList}>
            <h2 style={{ marginLeft: 10, color: "#9F6BA0" }}>
              Postal Code ({postalCodes?.length})
            </h2>
            <body style={{ marginLeft: 10, marginBottom: 20 }}>
              Select to choose
            </body>
            {postalCodes?.length > 0 ? (
              <List>
                {postalCodes.map((code, index) => (
                  <ListItem
                    key={index}
                    divider
                    style={{ cursor: "pointer" }}
                    secondaryAction={
                      <Typography
                        style={{
                          fontStyle: "italic",
                          color: "#9F6BA0",
                        }}
                        onClick={() => {
                          setOpen(true);
                          setSelectedPostalConfig(code);
                          setSelectedPostalDeliveryFees(
                            postalConfig[code]?.deliveryFees || ""
                          );
                        }}
                      >
                        Configure
                      </Typography>
                    }
                  >
                    <ListItemText
                      aria-multiline
                      primary={code}
                      secondary={showDelText(
                        postalConfig?.[code]?.deliveryFees
                      )}
                      onClick={() => setInputValue(code)}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography
                variant="body2"
                style={{ marginLeft: 10, marginBottom: 10 }}
              >
                No postal codes available.
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          container
          style={{
            marginBottom: 20,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <TextField
            label="Postal Code"
            style={{ width: "320px" }}
            variant="outlined"
            value={inputValue}
            error={postalCodeError}
            helperText={
              postalCodeError
                ? "Please enter a valid format like K2V or A4F"
                : "Valid format: Letter, Number, Letter"
            }
            onChange={handleInputChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          style={{
            marginBottom: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={addPostalCode}
            style={{
              marginLeft: 10,
              backgroundColor: "#9F6BA0",
              height: 40,
              width: 100,
            }}
          >
            Add
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => deletePostalCode(inputValue)}
            style={{ marginLeft: 10, height: 40, width: 100 }}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PostalCodeManager;

const styles = {
  scrollableList: {
    height: "500px",
    maxWidth: "300px",
    minWidth: "300px",
    overflowY: "scroll",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
};
