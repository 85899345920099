import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Categories from "./Categories";
import Coupon from "./Coupon";
import MessageTemplates from "./MessageTemplates";
import PostalConfig from "./PostalConfig";
import "./SideGradient.css";
import WeeklyMenu from "./WeeklyMenu";
const Configuration = () => {
  return (
    <Grid item xs={12} container direction="row">
      <Grid item xs={12} container>
        <Typography
          variant="h4"
          fontWeight="bold"
          style={{ marginBottom: 50, paddingInline: "20px" }}
        >
          Configuration
        </Typography>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12} lg={6} container>
          <Coupon />
        </Grid>
        <Grid
          item
          xs={0}
          lg={6}
          container
          alignItems="center"
          style={{ padding: "20px" }}
        >
          <Typography style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Add or Delete coupons, you can disable it as you want.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          style={{ marginBlock: 50 }}
          direction="column"
        >
          <Divider variant="middle" />
        </Grid>
      </Grid>
      {/* <Grid item xs={12} container>
        <Grid item xs={12} lg={6} container>
          <Holidays />
        </Grid>
        <Grid
          item
          xs={0}
          lg={6}
          container
          alignItems="center"
          style={{ padding: "20px" }}
        >
          <Typography style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Add or Delete holidays, it will automatically add or delete holidays
            to the orders according to start date and end date
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ marginBlock: 50 }}
        direction="column"
      >
        <Divider variant="middle" />
      </Grid> */}
      <Grid item xs={12} lg={6} container>
        <Categories />
      </Grid>
      <Grid
        item
        xs={0}
        lg={6}
        container
        alignItems="center"
        style={{ padding: "20px" }}
      >
        <Typography style={{ fontStyle: "italic", fontWeight: "bold" }}>
          Add or Update categories, orders can be categorized based on different
          factors such as areas of delivery, drivers etc.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ marginBlock: 50 }}
        direction="column"
      >
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={12} lg={6} container>
        <PostalConfig />
      </Grid>
      <Grid
        item
        xs={0}
        lg={6}
        container
        alignItems="center"
        style={{ padding: "20px" }}
      >
        <Typography style={{ fontStyle: "italic", fontWeight: "bold" }}>
          Add or Update postal codes, orders can only be placed only from
          provided postal codes and delivery charges can be customized.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ marginBlock: 50 }}
        direction="column"
      >
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={12} lg={6} container style={{ marginBottom: 10 }}>
        <WeeklyMenu />
      </Grid>
      <Grid
        item
        xs={0}
        lg={6}
        container
        alignItems="center"
        style={{ padding: "20px" }}
      >
        <Typography style={{ fontStyle: "italic", fontWeight: "bold" }}>
          You can leave a day empty if you don't deliver food on that day. Keep
          your menu up to date to avoid confusion
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ marginBlock: 50 }}
        direction="column"
      >
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={12} lg={6} container style={{ marginBottom: 10 }}>
        <MessageTemplates />
      </Grid>
      <Grid
        item
        xs={0}
        lg={6}
        container
        alignItems="center"
        style={{ padding: "20px" }}
      >
        <Typography style={{ fontStyle: "italic", fontWeight: "bold" }}>
          Some default messages are already there that are automatically send by
          software. You can add some of your templates as well. You need to add
          all these templates in twilio for whatsapp approval if you're using
          whatsapp messages.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Configuration;
