import moment from "moment";
import api from "./config";
import { encryptParams, encryptPayload } from "./encrypt";
import http from "./httpService";

const getOrders = async (values, timeframe, expDate) => {
  const { data } = await http.post(
    api + "/seller/orders",
    encryptPayload(values)
  );

  return data;
};
const getEOrders = async (values) => {
  const result = await http.post(api + "/order/all", encryptPayload(values));
  return result;
};

const getOrderById = async (values) => {
  const result = await http.get(api + "/order/" + encryptParams(values));
  return result;
};
const getPendingOrderById = async (values) => {
  const result = await http.get(
    api + "/pending/order/" + encryptParams(values)
  );
  return result;
};

const getActiveOrders = async (
  values,
  tDate = "",
  checkWeekdays = false,
  checkPauses = true
) => {
  const { data } = await http.post(
    api + "/seller/active/orders",
    encryptPayload(values)
  );
  const today = moment(tDate, "YYYY-MM-DD").startOf("day"); // Today's date at start of day
  const todayWeekday = today.isoWeekday() % 7; // ISO weekday (1 = Monday, ...)

  return data?.filter((order) => {
    if (checkPauses) {
      if (order?.pause) {
        return false;
      }
      if (order?.pauses) {
        for (const pause of order?.pauses) {
          const pauseStart = moment(pause?.start, "YYYY-MM-DD");
          const pauseEnd = moment(pause?.end, "YYYY-MM-DD").endOf("day");
          if (pauseStart <= today && pauseEnd >= today) {
            return false; // Order is paused today, so exclude it
          }
        }
      }
    }
    const startDate = moment(order?.sDate, "YYYY-MM-DD");
    const endDate = moment(order?.calEndDate, "YYYY-MM-DD").endOf("day");
    const isActivePeriod = startDate <= today && endDate >= today;
    // Check weekdays if required
    if (checkWeekdays && Array.isArray(order?.selDays)) {
      return isActivePeriod && order?.selDays?.includes(todayWeekday);
    }

    return isActivePeriod;
  });
};
const getActivePauseOrders = async (
  values,
  tDate = "",
  checkWeekdays = false,
  checkPauses = true
) => {
  const { data } = await http.post(
    api + "/seller/active/orders",
    encryptPayload(values)
  );
  const today = moment(tDate, "YYYY-MM-DD").startOf("day"); // Today's date at start of day
  const todayWeekday = today.isoWeekday(); // ISO weekday (1 = Monday, ...)

  return data?.filter((order) => {
    let isPauseAdded = false;
    if (order?.pause) {
      return false;
    }

    if (order?.pauses) {
      for (const pause of order?.pauses) {
        const pauseStart = moment(pause?.start, "YYYY-MM-DD");
        const pauseEnd = moment(pause?.end, "YYYY-MM-DD").endOf("day");
        if (pauseStart <= today && pauseEnd >= today) {
          isPauseAdded = true; // Order is paused today, so exclude it
        }
      }
    }

    const startDate = moment(order?.sDate, "YYYY-MM-DD");
    const endDate = moment(order?.calEndDate, "YYYY-MM-DD").endOf("day");
    const isActivePeriod = startDate <= today && endDate >= today;

    // Check weekdays if required
    if (checkWeekdays && Array.isArray(order?.selDays)) {
      return isActivePeriod && order?.selDays?.includes(todayWeekday);
    }

    return isActivePeriod && isPauseAdded;
  });
};
const getActiveOrdersNoValidation = async (values, tDate = "", validation) => {
  const { data } = await http.post(
    api + "/seller/active/orders",
    encryptPayload(values)
  );

  return data;
};
const getUnseenOrders = async (values) => {
  const result = await http.get(
    api + "/seller/unseen/orders/" + encryptParams(values)
  );
  return result;
};
const markOrderSeen = async (values) => {
  const result = await http.post(api + "/order/seen", encryptPayload(values));
  return result;
};

const saveOrder = async (values) => {
  const { data } = await http.post(
    api + "/order/manual/new",
    encryptPayload(values)
  );
  return data;
};
const saveOnlineOrder = async (values) => {
  const { data } = await http.post(
    api + "/online/order/new",
    encryptPayload(values)
  );
  return data;
};
const reOrder = async (values) => {
  const { data } = await http.post(api + "/reorder", encryptPayload(values));
  return data;
};
const editOrder = async (values) => {
  const { data } = await http.post(api + "/edit/order", encryptPayload(values));
  return data;
};
const checkRoute = async (values) => {
  const { data } = await http.get(
    api + "/check/route/" + encryptParams(values)
  );
  return data;
};
const getRouteById = async (values) => {
  const { data } = await http.get(api + "/routeById/" + encryptParams(values));
  return data;
};
const delivered = async (values) => {
  const { data } = await http.post(
    api + "/route/delivered",
    encryptPayload(values)
  );
  return data;
};
const generateRoute = async (values) => {
  const { data } = await http.get(api + "/route/" + encryptParams(values));
  return data;
};
const expiringOrders = async (values) => {
  const { data } = await http.get(
    api + "/order/expired/" + encryptParams(values?.toString())
  );
  return data;
};
const deleteOrder = async (values) => {
  const { data } = await http.post(
    api + "/delete/order/",
    encryptPayload(values)
  );
  return data;
};
const getPendingOrders = async (values) => {
  const { data } = await http.post(
    api + "/pending/orders/",
    encryptPayload(values)
  );
  return data;
};
const deleteDriverOrder = async (values) => {
  const { data } = await http.post(
    api + "/delete/driver/order",
    encryptPayload(values)
  );
  return data;
};
const deletePendingOrder = async (values) => {
  const { data } = await http.post(
    api + "/delete/pending/orders",
    encryptPayload(values)
  );
  return data;
};
const postDelivered = async (values) => {
  const { data } = await http.post(
    api + "/order/delivered",
    encryptPayload(values)
  );
  return data;
};
const getDelivered = async (values) => {
  const { data } = await http.get(
    api + "/get/order/delivered/" + encryptParams(values?.toString())
  );
  return data;
};
export {
  checkRoute,
  deleteDriverOrder,
  deleteOrder,
  deletePendingOrder,
  delivered,
  editOrder,
  expiringOrders,
  generateRoute,
  getActiveOrders,
  getActiveOrdersNoValidation,
  getActivePauseOrders,
  getDelivered,
  getEOrders,
  getOrderById,
  getOrders,
  getPendingOrderById,
  getPendingOrders,
  getRouteById,
  getUnseenOrders,
  markOrderSeen,
  postDelivered,
  reOrder,
  saveOnlineOrder,
  saveOrder,
};
