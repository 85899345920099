import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Alert,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { getOrderById } from "./api/order";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

const RelatedOrders = ({ orderIds }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        if (orderIds.length > 0) {
          const responses = await Promise.all(
            orderIds.map(async (id) => await getOrderById(id))
          );
          const fetchedOrders = responses.map((response) => response.data);
          setOrders(fetchedOrders);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [orderIds]);

  const handleCopy = (invoiceId) => {
    navigator.clipboard.writeText(invoiceId).then(() => {
      setSnackbarMessage(`Copied Invoice ID: ${invoiceId}`);
      setSnackbarOpen(true);
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">Failed to load orders</Alert>;
  }

  return (
    <>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        style={{ marginTop: 20, fontWeight: "bolder" }}
      >
        Related Orders
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Stripe Invoice</StyledTableCell>
              <StyledTableCell>Start Date</StyledTableCell>
              <StyledTableCell>End Date</StyledTableCell>
              <StyledTableCell>Copy</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.stripeInvoiceId}>
                <TableCell>{order.stripeInvoiceId}</TableCell>
                <TableCell>{order.sDate}</TableCell>
                <TableCell>{order.calEndDate}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleCopy(order.stripeInvoiceId)}>
                    <ContentCopyIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </>
  );
};

export default RelatedOrders;
