import React from "react";
import { Route, Routes } from "react-router-dom";
import DriverRoute from "./DriverRoute";
import HomePage from "./HomePage";
const OpenRoutes = () => {
  return (
    <Routes>
      <Route path="/route/:id" element={<DriverRoute />} />
      <Route path="/" element={<HomePage />} />
      {/* <Route path="*" element={<Navigate replace to="/" />} /> */}
      {/* <Route path="/" element={<Navigate replace to="/home" />} /> */}
    </Routes>
  );
};

export default OpenRoutes;
