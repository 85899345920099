const defaultMessages = [
  //0 Subscription confirmation
  `Hi {{1}}, your subscription has been confirmed! 
  
    Thank you for choosing our service. We appreciate your business!`,
  //1 Order confirmation
  `Hi {{1}}, your order has been confirmed! 
   
    Thank you for choosing our service. We appreciate your business!`,
  //2 Payment failed
  `Hi {{1}},
  
    We encountered an issue while processing your payment for the subscription. As a result, the payment has failed.`,
  //3 Subscription renewed
  `Hi {{1}},
  
    Good news! Your subscription has been successfully renewed. We're excited to continue providing you with our services.
      
    If you have any questions or need assistance, please feel free to contact us.
      
    Thank you for your continued support`,
  //4 Subscription canceled
  `Hi {{1}},
    
    We regret to inform you that your subscription has been canceled. Hope to provide you service again in future.`,
  //5 Subscription upcoming
  `Hi {{1}},
  
    This is a reminder that your upcoming payment for the subscription is due on {{2}}. Please make sure to update your payment method if it is expiring soon.
      
    Thank you!`,
];
const variablesCaption = "{{1}}=FIRST NAME, {{2}}=RENEWAL DATE";
module.exports = { defaultMessages, variablesCaption };
