import AddIcon from "@mui/icons-material/Add";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import HomeIcon from "@mui/icons-material/Home";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import MenuIcon from "@mui/icons-material/Menu";
import PreviewIcon from "@mui/icons-material/Preview";
import RouteIcon from "@mui/icons-material/Route";
import { Grid, ListItemIcon, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "./api/context";
import { getMenuBySelId } from "./api/menu";
import { getStripeAcc } from "./api/seller";
export default function SideBar() {
  const navigate = useNavigate();
  const drawerWidth = 200;
  const { user, setUser, side, setSide, setLoading } =
    React.useContext(DataContext);
  const [verified, setVerified] = React.useState(false);
  const [menuUrl, setMenuUrl] = React.useState(false);
  const [needInfo, setNeedInfo] = React.useState(false);
  const redirectToStripe = async () => {
    setLoading(true);
    // const { data } = await getStripeUrl(user.PayAccount);
    window.location.href = "https://dashboard.stripe.com/login";
  };
  const getRequirements = async () => {
    const { data } = await getMenuBySelId(user.SelID);

    if (data?._id) {
      setMenuUrl("https://www.dtmeals.com/menu/" + data?._id);
    }
    setVerified(data.isVerified);
  };
  const stripeInfoRequired = async () => {
    const { data } = await getStripeAcc(user.PayAccount);
    setNeedInfo(data.details_submitted);
  };
  React.useEffect(() => {
    if (user.SelID) {
      getRequirements();
      stripeInfoRequired();
    }
  }, [user]);
  const onSignOut = () => {
    setUser();
    localStorage.removeItem("token");
    window.location.href = "/";
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setSide({ ...side, [anchor]: open });
  };

  const list = (anchor) => (
    <>
      <Box
        sx={{ width: 200 }}
        role="presentation"
        onClick={toggleDrawer("left", false)}
        onKeyDown={toggleDrawer("left", false)}
        style={{
          background:
            "linear-gradient(0deg,rgba(105,145,214,.08),rgba(105,145,214,.08)),#fff",
        }}
      >
        <List>
          <ListItem
            button
            key="Home"
            style={{ justifyContent: "center", marginLeft: 20 }}
            onClick={() => navigate("/")}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Typography fontSize={30} fontWeight="bold">
              <span style={{ color: "#162328" }}>DT</span>{" "}
              <span style={{ color: "#9f6ba0" }}>Meals</span>
            </Typography>
          </ListItem>

          <ListItem />
          <ListItem button key="Home" onClick={() => navigate("/")}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontWeight: "bold", fontSize: 17 }}>
                  Home
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            key="Subscriptions"
            onClick={() => navigate("/subscriptions")}
          >
            <ListItemIcon>
              <LoyaltyIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontWeight: "bold", fontSize: 17 }}>
                  Subscriptions
                </Typography>
              }
            />
          </ListItem>
          <ListItem button key="Orders" onClick={() => navigate("/orders")}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontWeight: "bold", fontSize: 17 }}>
                  Orders
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            key="Routes/Labels"
            onClick={() => navigate("/area/order")}
          >
            <ListItemIcon>
              <RouteIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontWeight: "bold", fontSize: 17 }}>
                  Routes/Labels
                </Typography>
              }
            />
          </ListItem>
          {/* <ListItem button key="SignOut" onClick={() => navigate("/earnings")}>
          <ListItemText primary="Earnings Calculator" />
        </ListItem> */}
          {verified && (
            <ListItem
              button
              key="Store Preview"
              onClick={() => window.open(menuUrl, "_blank")}
            >
              <ListItemIcon>
                <PreviewIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography style={{ fontWeight: "bold", fontSize: 17 }}>
                    Store Preview
                  </Typography>
                }
              />
            </ListItem>
          )}
          {/* <ListItem button key="Store" onClick={() => navigate("/edit")}>
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontWeight: "bold", fontSize: 17 }}>
                  Store
                </Typography>
              }
            />
          </ListItem> */}
          {needInfo && (
            <ListItem button key="Earnings" onClick={() => redirectToStripe()}>
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography style={{ fontWeight: "bold", fontSize: 17 }}>
                    Stripe
                  </Typography>
                }
              />
            </ListItem>
          )}
          <ListItem
            button
            key="Add Order"
            onClick={() => (window.location.href = "/reorder")}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontWeight: "bold", fontSize: 17 }}>
                  Add Order
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            key="Holidays"
            onClick={() => navigate("/configuration")}
          >
            <ListItemIcon>
              <EventBusyIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontWeight: "bold", fontSize: 17 }}>
                  Configuration
                </Typography>
              }
            />
          </ListItem>
          <ListItem button key="SignOut" onClick={() => onSignOut()}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontWeight: "bold", fontSize: 17 }}>
                  Sign Out
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>
    </>
  );

  return (
    user && (
      <div>
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                onClick={toggleDrawer(anchor, true)}
                sx={{ display: { md: "none" }, padding: 0, marginRight: 1 }}
              >
                {" "}
                <MenuIcon style={{ color: "black" }} />
              </IconButton>
              <Typography
                fontSize={25}
                fontWeight="bold"
                sx={{ display: { md: "none" } }}
              >
                <span style={{ color: "#162328" }}>DT</span>{" "}
                <span style={{ color: "#9f6ba0" }}>Meals</span>
              </Typography>
            </Grid>
            <Drawer
              anchor={anchor}
              variant="temporary"
              open={side[anchor]}
              onClose={toggleDrawer(anchor, false)}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  border: 0,
                  width: drawerWidth,
                  background:
                    "linear-gradient(0deg,rgba(105,145,214,.08),rgba(105,145,214,.08)),#fff",
                },
              }}
            >
              {list(anchor)}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", md: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  border: 0,
                  background:
                    "linear-gradient(0deg,rgba(105,145,214,.08),rgba(105,145,214,.08)),#fff",
                },
              }}
              open
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    )
  );
}
