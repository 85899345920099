import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LocationSearchInput from "./LocationSearchInput";
import { getArea } from "./api/area";
import DataContext from "./api/context";
import { generatePdf } from "./api/label";
import { getActiveOrders } from "./api/order";
import { createExcel, createExcelData } from "./api/resources";
import { createRoute, getRouteBySelToday } from "./api/route";
const DriverRoute = () => {
  let currentDate = new Date().toJSON().slice(0, 10);
  const { user, setLoading } = useContext(DataContext);
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [incPhone, setIncPhone] = useState(true);
  const [areas, setAreas] = useState();
  const [areasArray, setAreasArray] = useState([]);
  const [orderArray, setOrderArray] = useState([]);
  const [dis, setDis] = useState(false);
  const [areaName, setAreaName] = useState("File");
  const [fields, setFields] = React.useState([]);
  const [servType, setServType] = React.useState("Delivery");
  const [driver, setDriver] = useState([]);
  const [routeConfigs, setRouteConfigs] = useState([]);
  const [rows, setRows] = useState([]);
  const [activeDate, setActiveDate] = useState(moment().format("YYYY-MM-DD"));
  const [address, setAddress] = useState();
  const [error, setError] = useState();
  const [coord, setCoord] = useState();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFields(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
  const fetchOrders = async (value) => {
    setOrders([]);
    let orderArr = [];
    driver.length > 0 &&
      (await Promise.all(
        driver.map(async (item) => {
          setLoading(true);
          let temp = areas.find((ar) => ar.aname === item).id;
          const data = await getActiveOrders(
            {
              SelID: user.SelID,
              driver: temp,
              serviceOpt: servType,
            },
            activeDate,
            true
          );
          orderArr = [...orderArr, ...data];
        })
      ));

    setOrderArray(orderArr);
    setLoading(false);
  };

  const getExcel = async (arr, date, areas) => {
    setLoading(true);
    const valuesArray = Object.values(areas);
    const commaSeparatedString = valuesArray.join(", ");
    const { orderArray, columns } = await createExcelData(
      arr,
      date,
      commaSeparatedString
    );
    createExcel(orderArray, columns);
    setLoading(false);
  };
  const fetchArea = async () => {
    let arr = [];
    const { areas } = await getArea(user.SelID);
    setAreas(areas);
    areas.map((ar) => arr.push(ar.aname));
    setAreasArray(arr);
  };
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const generateSortedArray = async () => {
    setLoading(true);
    let tempArray = [{ address: address, coord: coord }, ...orderArray];
    const k = await createRoute({
      SelID: user.SelID,
      driver: driver,
      items: tempArray,
      startAddress: address,
      date: moment().format("YYYY-MM-DD"),
      servType: servType,
    });
    setLoading(false);
    fetchTodayRoute();
  };
  useEffect(() => {
    if (user?.SelID && driver) {
      fetchArea();
      fetchOrders();
    }
  }, [user?.SelID, driver, activeDate, servType]);
  const fetchTodayRoute = async () => {
    const data = await getRouteBySelToday({
      SelID: user.SelID,
      date: moment().format("YYYY-MM-DD"),
    });
    setRouteConfigs(data);
  };
  const createPdf = async (sorArray) => {
    let sortArr = sorArray.slice(1);
    // sortArr.shift();
    let orderArray = [];
    setLoading(true);
    await Promise.all(
      sortArr.map(async (order) => {
        let paid = order.price === 0 ? "(U/P)" : "";
        let phNum;
        phNum = incPhone ? " | " + order.PhoneNumber : "";
        order?.request || order?.price === 0
          ? await orderArray.push([
              order.FirstName + phNum,
              order.address.substr(0, order.address.indexOf(",")),
              order.menuOpt.split(" ")[0] +
                " | " +
                (order?.prodName ? order?.prodName + " | " : "") +
                order?.roti +
                " Roti/" +
                order?.rice +
                " Rice/" +
                order?.sabzi +
                " Sabzi/" +
                order?.dal +
                " Dal" +
                " | " +
                order?.request +
                " " +
                paid,
            ])
          : await orderArray.push([
              order.FirstName + phNum,
              order.address.substr(0, order.address.indexOf(",")),
              order.menuOpt.split(" ")[0] +
                " | " +
                (order?.prodName ? order?.prodName + " | " : "") +
                order?.roti +
                " Roti/" +
                order?.rice +
                " Rice/" +
                order?.sabzi +
                " Sabzi/" +
                order?.dal +
                " Dal",
            ]);
      })
    );
    const { data } = await generatePdf({ labels: orderArray });
    await delay(5000);
    window.open(
      `https://labelpdf.s3.ca-central-1.amazonaws.com/${data}.pdf`,
      "_blank"
    );
    setLoading(false);
  };
  useEffect(() => {
    fetchTodayRoute();
  }, [user?.SelID]);
  const names = ["Duration", "Amount", "Phone", "OrderDate"];
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <Grid container style={{ paddingRight: 30 }}>
      <Grid
        item
        xs={12}
        container
        style={{ padding: 20, paddingTop: 0, paddingLeft: 0 }}
      >
        <Typography variant="h4" fontWeight="bold">
          Routes & Labels
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ padding: 20, paddingTop: 0, paddingLeft: 0 }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "#9f6ba0" }}
          onClick={() => navigate("/reports")}
        >
          Older reports
        </Button>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        {areasArray.length > 0 && (
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={areasArray}
            disableCloseOnSelect
            value={driver}
            onChange={(event, value) => setDriver(value)}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            // style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Categories"
                placeholder="Select Categories"
              />
            )}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Service Type</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={servType}
            label="Service Type"
            onChange={(e) => {
              setServType(e.target.value);
            }}
          >
            <MenuItem value={"Delivery"}>Delivery</MenuItem>
            <MenuItem value={"Pickup"}>Pickup</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <LocationSearchInput
        setAddress={setAddress}
        error={error}
        setCoord={setCoord}
        address={address}
        placeholder="Starting Location"
      />
      <Grid item xs={12} container justifyContent="center">
        <Button
          variant="contained"
          onClick={() => generateSortedArray()}
          disabled={orderArray.length > 0 && coord && address ? false : true}
        >
          Generate
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography
          style={{
            fontWeight: "bolder",
            fontSize: 20,
            marginBlock: 50,
          }}
        >
          Generated Today
        </Typography>
      </Grid>
      <Grid item xs={12} container alignItems="center" justifyContent="right">
        <Switch
          aria-label="Include Phone Number"
          checked={incPhone}
          onChange={(value) => setIncPhone(value.target.checked)}
        />
        <Typography style={{ fontWeight: "bold" }}>
          Include Phone Number
        </Typography>
      </Grid>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Categories</TableCell>
                <TableCell align="center">Service Type</TableCell>
                <TableCell align="center">Starting Location</TableCell>
                <TableCell align="center">Total Distance</TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {routeConfigs.map((config) => (
                <>
                  <TableRow hover role="checkbox">
                    <TableCell key={1} align="center">
                      <Typography style={{ fontSize: 20 }}>
                        {config?.driver?.toString()}
                      </Typography>
                    </TableCell>
                    <TableCell key={1} align="center">
                      <Typography>{config?.servType}</Typography>
                    </TableCell>
                    <TableCell key={1} align="center">
                      <Typography>{config?.sortedArray[0]?.address}</Typography>
                    </TableCell>
                    <TableCell key={1} align="center">
                      <Typography>
                        {(config?.distance / 1000).toFixed(1) + " km"}
                      </Typography>
                    </TableCell>
                    <TableCell key={1} align="center">
                      <Button
                        style={{ paddingLeft: "0px" }}
                        onClick={() => navigate(`/route/${config._id}`)}
                      >
                        Route
                      </Button>
                    </TableCell>

                    <TableCell key={1} align="center">
                      <Button
                        style={{ paddingLeft: "0px" }}
                        onClick={() => createPdf(config?.sortedArray)}
                      >
                        Labels
                      </Button>
                    </TableCell>

                    <TableCell key={1} align="center">
                      <Button
                        style={{ paddingLeft: "0px" }}
                        onClick={() =>
                          getExcel(
                            config?.sortedArray,
                            config?.date,
                            config?.driver
                          )
                        }
                      >
                        Excel
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default DriverRoute;
