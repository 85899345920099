import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AreaSelect from "./AreaSelect";
import BasicSelect from "./BasicSelect";
import CopyTextField from "./CopyTextField";
import DatePickerUI from "./DatePickerUI";
import DaySelector from "./DaySelector";
import LocationSearchInput from "./LocationSearchInput";
import OrderPreview from "./OrderPreview";
import RelatedOrders from "./RelatedOrders";
import OrderHistory from "./Seller/OrderHistory";
import { getArea } from "./api/area";
import DataContext from "./api/context";
import { getMenuBySelId } from "./api/menu";
import { calculateAdjustedEndDate } from "./api/resources";
import {
  cancelSubscriptionAtEnd,
  confirmSubscription,
  deletePendingSubscription,
  getAnySubscriptions,
  getPendingSubscriptionById,
  getSubscriptionsBySel,
  resumeCancelSubscriptionAtEnd,
  updateSubscription,
} from "./api/subscription";
const SubscriptionSchema = Yup.object().shape({
  prodName: Yup.string().required("Required"),
  menuOpt: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email"),
  serviceOpt: Yup.string().required("Required"),
  rice: Yup.string()
    .required("Required")
    .matches(/^[0-9]\d*$/, "Please enter the correct amount"),
  roti: Yup.string()
    .required("Required")
    .matches(/^[0-9]\d*$/, "Please enter the correct amount"),
  sabzi: Yup.string()
    .required("Required")
    .matches(/^[0-9]\d*$/, "Please enter the correct amount"),
  dal: Yup.string()
    .required("Required")
    .matches(/^[0-9]\d*$/, "Please enter the correct amount"),
  // rsOpt: Yup.string().required("Required"),
  // number: Yup.number().positive("Must be a positive number or zero").nullable(),
  pause: Yup.boolean(),
  FirstName: Yup.string().required("Required"),
  LastName: Yup.string().nullable(),
  PhoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
  sDate: Yup.date().required(),
  request: Yup.string().nullable(),
  note: Yup.string().nullable(),
  selDays: Yup.array(),
  driver: Yup.number().required("Required"),
  requiredWeekdays: Yup.number().required("Required"),
  pauses: Yup.array().of(
    Yup.object({
      start: Yup.date().required("Start date is required"),
      end: Yup.date().required("End date is required"),
    })
  ),
});
const SubscriptionForm = ({}) => {
  const navigate = useNavigate();
  const { user, setLoading } = useContext(DataContext);
  const [address, setAddress] = useState("");
  const [coord, setCoord] = useState();
  const [paid, setPaid] = useState(true);
  const [addErr, setAddErr] = useState(false);
  const [orderData, setOrderData] = useState();
  const menuOptions = ["Veg Menu", "Non-Veg Menu"];
  const serviceOptions = ["Delivery", "Pickup", "Takeout"];
  const [prodOptions, setProdOptions] = useState([]);
  const [previewData, setPreviewData] = useState();
  const [pauseStartDate, setPauseStartDate] = useState(moment());
  const [pauseEndDate, setPauseEndDate] = useState(moment());
  const [hData, setHData] = useState();
  const [hCount, setHCount] = useState();
  const [areas, setAreas] = useState();
  const [addReqDays, setAddReqDays] = useState(0);
  const [incHolidays, setIncHolidays] = useState([]);
  const [initial, setInitial] = useState({
    prodName: "",
    menuOpt: "Veg Menu",
    serviceOpt: "Delivery",
    // rsOpt: "",
    price: 0,
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    email: "",
    sDate: moment().format("YYYY-MM-DD"),
    eDate: "",
    request: "",
    note: "",
    pause: false,
    driver: "",
    roti: "",
    rice: "",
    dal: "",
    sabzi: "",
    selDays: [1, 2, 3, 4, 5],
    isPaid: true,
    requiredWeekdays: "",
    renewalDate: "",
    addRequiredWeekdays: 0,
    pauses: [],
  });
  const para = window.location.pathname.split("/");
  let { id } = useParams();
  const fetchOptions = async () => {
    const { areas } = await getArea(user.SelID);
    setAreas(areas);
  };
  const handleCancelSubscription = async () => {
    const result = await cancelSubscriptionAtEnd({
      _id: id,
      SelID: user.SelID,
    });
    window.location.reload();
  };
  const handleResumeSubscription = async () => {
    const result = await resumeCancelSubscriptionAtEnd({
      _id: id,
      SelID: user.SelID,
    });
    window.location.reload();
  };
  React.useEffect(() => {
    fetchOptions();
  }, [user]);
  const fetchData = async () => {
    if (id) {
      setLoading(true);

      let data;
      if (para[1] === "past") {
        const vData = await getAnySubscriptions({
          SelID: user.SelID,
          _id: id,
        });
        data = vData[0];
        setPreviewData(vData[0]?.previewData);
      } else if (para[1] === "pending") {
        const { data: vData } = await getPendingSubscriptionById(id);
        data = vData;
        setPreviewData(vData);
      } else {
        const vData = await getSubscriptionsBySel({
          SelID: user.SelID,
          _id: id,
        });
        data = vData[0];
        setPreviewData(vData[0]?.previewData);
      }
      setOrderData(data);
      setPaid(data?.price ? true : false);
      if (para[1] === "edit" || para[1] === "past") {
        setInitial({
          prodName: data?.prodName,
          menuOpt: data?.menuOpt,
          rice: data?.rice,
          roti: data?.roti,
          sabzi: data?.sabzi,
          dal: data?.dal,
          rsOpt: data?.rsOpt,
          price: data?.price,
          FirstName: data?.FirstName,
          LastName: data?.LastName,
          PhoneNumber: data?.PhoneNumber,
          email: data?.email,
          sDate: data?.sDate,
          eDate: data?.eDate,
          serviceOpt: data?.serviceOpt,
          request: data?.request,
          note: data?.note,
          driver: data?.driver,
          pause: data?.pause,
          selDays: data?.selDays ? data?.selDays : [1, 2, 3, 4, 5],
          isPaid: data?.isPaid,
          requiredWeekdays: data?.requiredWeekdays,
          renewalDate: data?.renewalDate,
          addRequiredWeekdays: data?.addRequiredWeekdays
            ? data?.addRequiredWeekdays
            : 0,
          pauses: data?.pauses ? data?.pauses : [],
        });
        setAddress(data?.address);
      } else if (para[1] === "pending") {
        setInitial({
          prodName: data.prodType.prodName,
          menuOpt: data.menuOpt.menuType,
          rice: data?.rrOpt?.rice + parseInt(data?.xrice),
          roti: data?.rrOpt?.roti + parseInt(data?.xroti),
          sabzi: data?.rrOpt?.sabzi + parseInt(data?.xsabzi),
          dal: data?.rrOpt?.dal + parseInt(data?.xdal),
          // rsOpt: data?.rsOpt,
          price: data.totalPrice,
          FirstName: data?.FirstName,
          LastName: data?.LastName,
          PhoneNumber: data?.PhoneNumber.substring(2),
          email: data?.email,
          sDate: data?.sDate,
          eDate: data?.eDate,
          serviceOpt: data?.serviceOpt,
          request: data?.request,
          note: data?.note,
          driver: data?.driver,
          pause: data?.pause,
          selDays: data?.prodType?.deliveryDays
            ? data?.prodType?.deliveryDays
            : [1, 2, 3, 4, 5],
          isPaid: data?.isPaid,
          requiredWeekdays: data?.days,
          renewalDate: data?.renewalDate,
          addRequiredWeekdays: data?.addRequiredWeekdays
            ? data?.addRequiredWeekdays
            : 0,
          pauses: data?.pauses ? data?.pauses : [],
        });
        setAddress(data?.address);
      } else {
        setInitial({
          prodName: data?.prodName,
          menuOpt: data.menuOpt,
          rice: data?.rice,
          roti: data?.roti,
          sabzi: data?.sabzi,
          dal: data?.dal,
          // rsOpt: data.rsOpt,
          price: 0,
          FirstName: data?.FirstName,
          LastName: data?.LastName,
          PhoneNumber: data?.PhoneNumber,
          email: data?.email,
          sDate: moment().format("YYYY-MM-DD"),
          eDate: "",
          serviceOpt: data?.serviceOpt,
          request: data?.request,
          note: data?.note,
          driver: data?.driver,
          pause: data?.pause,
          selDays: data?.selDays ? data?.selDays : [1, 2, 3, 4, 5],
          isPaid: data?.isPaid,
        });
        setPaid(false);
      }
      setCoord(data?.coord);
      setAddress(data?.address);
      setLoading(false);
    }
  };
  const fetchMenu = async () => {
    const { data: hData } = await getMenuBySelId(user.SelID);
    const { products } = hData;
    setProdOptions(products.map((obj) => obj.prodName));
    setHData(hData);
  };

  useEffect(() => {
    fetchData();
    fetchMenu();
  }, [id]);
  const getHolidays = (values) => {
    // Convert the start and end dates to Date objects
    const start = moment(values.sDate);
    const end = moment(values.eDate);
    let holidays = [];
    if (hData?.holidays) {
      holidays = hData?.holidays;
    }
    // Filter the holidays to find those within the range
    let holidaysInRange = [];
    if (holidays.length > 0) {
      holidaysInRange = holidays.filter((holiday) => {
        const holidayDate = moment(holiday);
        return (
          holidayDate.isSameOrAfter(start) && holidayDate.isSameOrBefore(end)
        );
      });
    }

    return holidaysInRange;
  };
  const getEndDate = (values) => {
    if (values.requiredWeekdays) {
      return calculateAdjustedEndDate(
        values.sDate,
        values.selDays,
        parseInt(values.requiredWeekdays) +
          parseInt(values.addRequiredWeekdays ? values.addRequiredWeekdays : 0),
        values.pauses,
        hData?.holidays
      );
    }
  };

  const delPendingSubscription = async () => {
    let cancellationReason = prompt(
      "Do you want to cancel? You are solely responsible for cancellation and refund handling including removing subscription from your stripe account.",
      "Please enter a good reason"
    );
    if (cancellationReason == null || cancellationReason == "") {
    } else {
      previewData.cancellationReason = cancellationReason;
      previewData.status = "cancelled";

      navigate("/orders");
    }
  };

  const formik = useFormik({
    initialValues: initial,
    enableReinitialize: true,
    validationSchema: SubscriptionSchema,

    onSubmit: async (values, actions) => {
      if (address && coord) {
        setLoading(true);
        values.address = address;
        values.SelID = user.SelID;
        values.coord = coord;
        values.rrOpt = `${values.roti} Roti + ${values.rice} Rice`;
        if (id) {
          if (para[1] === "edit" || para[1] === "past") {
            let subscriptionValues = {
              ...orderData,
              ...values,
              ...{ calEndDate: getEndDate(values) },
              ...{ calculatedEndDate: new Date(getEndDate(values)) },
            };
            if (
              moment(previewData.renewalDate).isSameOrBefore(getEndDate(values))
            ) {
              subscriptionValues = {
                ...subscriptionValues,
                ...{
                  renewalDate: moment(getEndDate(values))
                    .add(1, "days")
                    .format("YYYY-MM-DD"),
                },
              };
            }
            await updateSubscription(subscriptionValues);
          } else if (para[1] === "pending") {
            await deletePendingSubscription(previewData);

            let subscriptionValues = {
              ...values,
              ...{ CusID: previewData.CusID },
              ...{ sub_id: previewData.sub_id },
              ...{
                completedCheckoutSession: previewData.completedCheckoutSession,
              },
              ...{
                eDate: previewData.eDate,
              },
              ...{ status: "active" },
              ...{ ch_id: previewData.ch_id },
              ...{ savings: previewData.savings },
              ...{ calEndDate: getEndDate(values) },
              ...{ paymentMode: previewData.paymentMode },
              ...{ isOnlineOrder: previewData.isOnlineOrder },
              ...{ previewData: previewData },
              ...{ endDate: new Date(values.eDate) },
              ...{ startDate: new Date(values.sDate) },
              ...{ stripeInvoiceId: previewData.firstInvoice },
              ...{ billingType: previewData.billingType },
            };
            if (
              moment(previewData.renewalDate).isSameOrBefore(getEndDate(values))
            ) {
              subscriptionValues = {
                ...subscriptionValues,
                ...{
                  renewalDate: moment(getEndDate(values))
                    .add(1, "days")
                    .format("YYYY-MM-DD"),
                  changedRenewalDate: true,
                },
              };
            }
            await confirmSubscription(subscriptionValues);
          } else {
          }
        }
        setLoading(false);
        alert("Subscription Saved Successfully");
        if (id) {
          if (
            para[1] === "edit" ||
            para[1] === "past" ||
            para[1] === "pending"
          ) {
            navigate("/subscriptions?tabInd=1");
          } else if (para[1] === "pending") {
            navigate("/subscriptions?tabInd=0");
          }
        } else {
          navigate("/subscriptions?tabInd=0");
        }
        actions.resetForm({
          values: {
            prodName: "",
            menuOpt: "",
            rsOpt: "",
            price: "",
            FirstName: "",
            LastName: "",
            PhoneNumber: "",
            email: "",
            serviceOpt: "",
            sDate: "",
            eDate: "",
            request: "",
            driver: "",
            roti: "",
            rice: "",
            sabzi: "",
            dal: "",
            requiredWeekdays: "",
            pause: false,
          },
        });
        setAddress("");
        setCoord("");
      } else {
        setAddErr(true);
      }
    },
  });

  return (
    <Grid container item md={12} style={{ padding: 20 }}>
      <Grid item xs={12} container>
        {para[1] === "edit" || para[1] === "past" ? (
          <Typography
            variant="h4"
            fontWeight="bold"
            style={{ marginBottom: 20 }}
          >
            Edit Subscription
          </Typography>
        ) : (
          <Typography
            variant="h4"
            fontWeight="bold"
            style={{ marginBottom: 20 }}
          >
            Add Subscription
          </Typography>
        )}
      </Grid>
      <Grid item md={12} lg={6} container>
        <form enableReinitialize={true} onSubmit={formik.handleSubmit}>
          <Grid item container direction="row">
            <Typography style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: "bolder", marginRight: 10 }}>
                Order ID:
              </span>
              {orderData?._id?.slice(-5)}
            </Typography>
            <CopyTextField
              text={orderData?.sub_id}
              label="Stripe subscription id"
            />
            <TextField
              style={{ marginBlock: 10 }}
              fullWidth
              disabled={true}
              id="FirstName"
              name="FirstName"
              label="First Name"
              variant="outlined"
              value={formik.values?.FirstName}
              onChange={formik.handleChange}
              error={
                formik.touched.FirstName && Boolean(formik.errors.FirstName)
              }
              helperText={formik.touched.FirstName && formik.errors.FirstName}
            />
            <TextField
              style={{ marginBlock: 10 }}
              fullWidth
              disabled={true}
              id="LastName"
              name="LastName"
              label="Last Name"
              variant="outlined"
              value={formik.values?.LastName}
              onChange={formik.handleChange}
              error={formik.touched.LastName && Boolean(formik.errors.LastName)}
              helperText={formik.touched.LastName && formik.errors.LastName}
            />

            <BasicSelect
              style={{ marginBlock: 10 }}
              fullWidth
              id="prodName"
              name="prodName"
              label="Product"
              variant="outlined"
              options={prodOptions}
              value={formik.values?.prodName}
              onChange={formik.handleChange}
              error={formik.touched.prodName && Boolean(formik.errors.prodName)}
              helperText={formik.touched.prodName && formik.errors.prodName}
            />
            <BasicSelect
              style={{ marginBlock: 10 }}
              fullWidth
              id="menuOpt"
              name="menuOpt"
              label="Menu"
              variant="outlined"
              options={menuOptions}
              value={formik.values?.menuOpt}
              onChange={formik.handleChange}
              error={formik.touched.menuOpt && Boolean(formik.errors.menuOpt)}
              helperText={formik.touched.menuOpt && formik.errors.menuOpt}
            />
            <BasicSelect
              style={{ marginBlock: 10 }}
              fullWidth
              id="serviceOpt"
              name="serviceOpt"
              label="Service Type"
              variant="outlined"
              options={serviceOptions}
              value={formik.values?.serviceOpt}
              onChange={formik.handleChange}
              error={
                formik.touched.serviceOpt && Boolean(formik.errors.serviceOpt)
              }
              helperText={formik.touched.serviceOpt && formik.errors.serviceOpt}
            />
            <TextField
              style={{ marginBlock: 10 }}
              fullWidth
              id="roti"
              name="roti"
              label="Roti"
              type="number"
              variant="outlined"
              value={formik.values?.roti}
              onChange={formik.handleChange}
              error={formik.touched.roti && Boolean(formik.errors.roti)}
              helperText={formik.touched.roti && formik.errors.roti}
            />
            <TextField
              style={{ marginBlock: 10 }}
              fullWidth
              id="rice"
              name="rice"
              label="Rice"
              type="number"
              variant="outlined"
              value={formik.values?.rice}
              onChange={formik.handleChange}
              error={formik.touched.rice && Boolean(formik.errors.rice)}
              helperText={formik.touched.rice && formik.errors.rice}
            />
            <TextField
              style={{ marginBlock: 10 }}
              fullWidth
              id="sabzi"
              name="sabzi"
              label="Sabzi"
              type="number"
              variant="outlined"
              value={formik.values?.sabzi}
              onChange={formik.handleChange}
              error={formik.touched.sabzi && Boolean(formik.errors.sabzi)}
              helperText={formik.touched.sabzi && formik.errors.sabzi}
            />
            <TextField
              style={{ marginBlock: 10 }}
              fullWidth
              id="dal"
              name="dal"
              label="Dal"
              type="number"
              variant="outlined"
              value={formik.values?.dal}
              onChange={formik.handleChange}
              error={formik.touched.dal && Boolean(formik.errors.dal)}
              helperText={formik.touched.dal && formik.errors.dal}
            />
            {/* <TextField
            style={{ marginBlock: 10 }}
            fullWidth
            id="rsOpt"
            name="rsOpt"
            label="Salad/Raita option"
            variant="outlined"
            value={formik.values?.rsOpt}
            onChange={formik.handleChange}
            error={formik.touched.rsOpt && Boolean(formik.errors.rsOpt)}
            helperText={formik.touched.rsOpt && formik.errors.rsOpt}
          /> */}
            {/* {!!!formik.values.isPaid && (
              <Grid item xs={12} container alignItems="center">
                <Typography>Paid </Typography>
                <Switch
                  checked={paid}
                  onChange={(e) => {
                    setPaid(e.target.checked);
                    formik.setFieldValue("price", e.target.checked ? "" : 0);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  defaultChecked
                />
              </Grid>
            )} */}
            {/* {paid === true && ( */}
            <TextField
              style={{ marginBlock: 10 }}
              disabled={true}
              fullWidth
              id="price"
              name="price"
              label="Amount Paid"
              variant="outlined"
              value={formik.values?.price}
              onChange={formik.handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
            />
            {/* )} */}
            <TextField
              style={{ marginBlock: 10 }}
              fullWidth
              disabled={true}
              id="PhoneNumber"
              name="PhoneNumber"
              label="Phone Number"
              variant="outlined"
              value={formik.values?.PhoneNumber}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+1</InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              error={
                formik.touched.PhoneNumber && Boolean(formik.errors.PhoneNumber)
              }
              helperText={
                formik.touched.PhoneNumber && formik.errors.PhoneNumber
              }
            />
            <TextField
              fullWidth
              id="email"
              disabled={true}
              name="email"
              label="Email"
              type="email"
              value={formik.values.email}
              values
              style={{
                color: "#9F6BA0",
                borderColor: "#9F6BA0",
              }}
              InputProps={{
                style: {
                  borderRadius: "40px",
                },
              }}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            {para[1] !== "past" && (
              <>
                <AreaSelect
                  style={{ marginBlock: 10 }}
                  fullWidth
                  id="driver"
                  name="driver"
                  label="Category"
                  variant="outlined"
                  value={formik.values?.driver}
                  onChange={formik.handleChange}
                  error={formik.touched.driver && Boolean(formik.errors.driver)}
                  helperText={formik.touched.driver && formik.errors.driver}
                />

                <Grid item xs={12} container alignItems="center">
                  <TextField
                    style={{ marginBlock: 10 }}
                    disabled={true}
                    id="sDate"
                    name="sDate"
                    type="date"
                    label="Start Date"
                    variant="outlined"
                    fullWidth
                    value={formik.values?.sDate}
                    onChange={formik.handleChange}
                    error={formik.touched.sDate && Boolean(formik.errors.sDate)}
                    helperText={formik.touched.sDate && formik.errors.sDate}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <TextField
                    style={{ marginBlock: 10 }}
                    id="requiredWeekdays"
                    name="requiredWeekdays"
                    type="number"
                    label="Required days"
                    variant="outlined"
                    fullWidth
                    disabled={true}
                    value={formik.values?.requiredWeekdays}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.requiredWeekdays &&
                      Boolean(formik.errors.requiredWeekdays)
                    }
                    helperText={
                      formik.touched.requiredWeekdays &&
                      formik.errors.requiredWeekdays
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <TextField
                    style={{ marginBlock: 10 }}
                    id="addRequiredWeekdays"
                    name="addRequiredWeekdays"
                    type="number"
                    label="Additional Required days"
                    variant="outlined"
                    fullWidth
                    value={formik.values?.addRequiredWeekdays}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.addRequiredWeekdays &&
                      Boolean(formik.errors.addRequiredWeekdays)
                    }
                    helperText={
                      formik.touched.addRequiredWeekdays &&
                      formik.errors.addRequiredWeekdays
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: 5,
                    padding: 10,
                  }}
                >
                  <Grid item xs={12} container>
                    <Typography style={{ marginRight: 10 }}>
                      Cycle End Dates
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container>
                    <Typography style={{ marginRight: 10 }}>
                      Computed - {getEndDate(formik.values)} | Original -{" "}
                      {formik.values.eDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: 5,
                    padding: 10,
                    marginTop: 10,
                  }}
                >
                  {" "}
                  <Grid item xs={12} container>
                    <Typography style={{ marginRight: 10 }}>
                      Renewal Date
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container>
                    <Typography style={{ marginRight: 10 }}>
                      Computed -{" "}
                      {moment(formik.values?.renewalDate).isSameOrBefore(
                        getEndDate(formik.values)
                      )
                        ? moment(getEndDate(formik.values))
                            .add(1, "days")
                            .format("YYYY-MM-DD")
                        : formik.values?.renewalDate}{" "}
                      | Original - {formik.values?.renewalDate}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  container
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: 5,
                    padding: 10,
                    marginBlock: 10,
                  }}
                >
                  <Grid item xs={12} container>
                    <Typography style={{ marginRight: 10 }}>
                      Holidays Included
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container>
                    <Typography style={{ marginRight: 10 }}>
                      {getHolidays(formik.values)?.map((it) => it + "| ")}
                    </Typography>
                  </Grid>
                </Grid> */}
                {/* {hCount ? (
                  <Chip
                    label={"+" + hCount + " Holidays"}
                    style={{ marginLeft: 10 }}
                    color="primary"
                    variant="outlined"
                  />
                ) : (
                  ""
                )} */}
                <Grid
                  item
                  xs={12}
                  container
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: 5,
                    padding: 10,
                    marginBlock: 10,
                  }}
                >
                  <Grid item xs={12} container>
                    <Typography style={{ marginRight: 10 }}>Pauses</Typography>
                  </Grid>
                  <Grid item xs={12} container>
                    <Typography style={{ marginRight: 10 }}>
                      <List>
                        {formik.values?.pauses?.map((it, index) => (
                          <ListItem
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  formik.setFieldValue(
                                    "pauses",
                                    formik.values?.pauses?.filter(
                                      (element, ind) => ind !== index
                                    )
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                          >
                            {/* <ListItemAvatar>
                              <Avatar>
                                <PauseCircleIcon />
                              </Avatar>
                            </ListItemAvatar> */}
                            {it?.start + " "} <ArrowForwardIcon />{" "}
                            {" " + it?.end}
                          </ListItem>
                        ))}
                      </List>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container>
                    <DatePickerUI
                      pauseStartDate={pauseStartDate}
                      setPauseStartDate={setPauseStartDate}
                      pauseEndDate={pauseEndDate}
                      setPauseEndDate={setPauseEndDate}
                    />
                    <Grid item xs={12} container>
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          marginTop: 20,
                          backgroundColor: "#9f6ba0",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          formik.setFieldValue("pauses", [
                            ...formik.values?.pauses,
                            ...[
                              {
                                start: moment(new Date(pauseStartDate)).format(
                                  "YYYY-MM-DD"
                                ),
                                end: moment(new Date(pauseEndDate)).format(
                                  "YYYY-MM-DD"
                                ),
                              },
                            ],
                          ]);
                        }}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <TextField
                  style={{ marginBlock: 10 }}
                  fullWidth
                  id="request"
                  name="request"
                  label="Additional Request"
                  variant="outlined"
                  value={formik.values?.request}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.request && Boolean(formik.errors.request)
                  }
                  helperText={formik.touched.request && formik.errors.request}
                />
                <TextField
                  style={{ marginBlock: 10 }}
                  fullWidth
                  id="note"
                  name="note"
                  label="Personal Notes"
                  variant="outlined"
                  value={formik.values?.note}
                  onChange={formik.handleChange}
                  error={formik.touched.note && Boolean(formik.errors.note)}
                  helperText={formik.touched.note && formik.errors.note}
                />
                <Grid
                  item
                  xs={12}
                  container
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: 5,
                    padding: 10,
                    marginBlock: 10,
                  }}
                >
                  {para[1] === "pending" && (
                    <Grid item xs={12} container>
                      <Alert severity="info">
                        This setting can't be changed later
                      </Alert>
                    </Grid>
                  )}
                  <DaySelector
                    selDays={formik.values?.selDays}
                    disabled={para[1] === "edit"}
                    handleChange={formik.setFieldValue}
                  />
                </Grid>
                {para[1] !== "pending" && (
                  <Grid item xs={12} container alignItems="center">
                    <Typography>Pause</Typography>
                    <Switch
                      id="pause"
                      name="pause"
                      checked={formik.values?.pause}
                      onChange={(e) =>
                        formik.setFieldValue("pause", e.target.checked)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                      defaultChecked
                    />
                  </Grid>
                )}
                <LocationSearchInput
                  setAddress={setAddress}
                  address={address}
                  error={addErr}
                  setCoord={setCoord}
                />
              </>
            )}

            {orderData?.cancel_at_period_end &&
              orderData?.status !== "canceled" && (
                <Grid item xs={12} container>
                  <Alert severity="error">Cancelling at period end</Alert>
                </Grid>
              )}

            {orderData?.status === "canceled" && (
              <Grid item xs={12} container>
                <Alert severity="error">Canceled subscription</Alert>
              </Grid>
            )}

            {orderData?.status !== "canceled" &&
              (para[1] === "edit" || para[1] === "pending") && (
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  style={{
                    marginTop: 20,
                    backgroundColor: "#9f6ba0",
                    fontWeight: "bold",
                  }}
                >
                  Save Subscription
                </Button>
              )}
            {para[1] === "edit" &&
              !!!orderData?.cancel_at_period_end &&
              orderData?.status !== "canceled" && (
                <Button
                  variant="contained"
                  onClick={() => handleCancelSubscription()}
                  fullWidth
                  style={{
                    marginTop: 20,
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Cancel subscription at end
                </Button>
              )}
            {para[1] === "edit" &&
              orderData?.cancel_at_period_end &&
              orderData?.status !== "canceled" && (
                <Button
                  variant="contained"
                  onClick={() => handleResumeSubscription()}
                  fullWidth
                  style={{
                    marginTop: 20,
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Resume Subscription
                </Button>
              )}
            {orderData?.relatedOrders && (
              <RelatedOrders orderIds={orderData?.relatedOrders} />
            )}
            <Grid item xs={12} container>
              <Typography
                style={{ marginTop: 50, fontWeight: "bold" }}
                fontSize={20}
              >
                <i>History</i>
              </Typography>
            </Grid>
            {orderData && areas && (
              <OrderHistory
                openHistory={true}
                index={1}
                order={orderData}
                data={orderData?.history}
                orderDate={orderData?.date}
                areas={areas}
                collapsible={true}
              />
            )}
          </Grid>
        </form>
      </Grid>
      {!!previewData && (
        <Grid item md={12} lg={6} container style={{ padding: 20 }}>
          <Paper
            elevation={3}
            style={{ width: "100%", padding: 30, maxHeight: "700px" }}
          >
            <OrderPreview order={previewData} />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default SubscriptionForm;
