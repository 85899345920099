import { ContentCopy } from "@mui/icons-material";
import {
  Alert,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const CopyTextField = ({ text, label }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(
      () => {
        setSnackbarOpen(true);
      },
      () => {
        setSnackbarOpen(true);
      }
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <TextField
        label={label}
        id={label}
        name={label}
        disabled={true}
        value={text}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleCopy}>
                <ContentCopy />
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        fullWidth
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {label} copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
};

export default CopyTextField;
