import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AreaOrders from "./AreaOrders";
import Configuration from "./Configuration";
import DriverRoute from "./DriverRoute";
import EarningsCalculator from "./EarningsCalculator";
import Holidays from "./Holidays";
import OrderForm from "./OrderForm";
import Reorder from "./Reorder";
import Reports from "./Reports";
import Orders from "./Seller/Orders";
import SellerDash from "./Seller/SellerDash";
import Subscriptions from "./Seller/Subscriptions";
import StripeAuthorize from "./StripeAuthorize";
import SubscriptionForm from "./SubscriptionForm";
const ServiceRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/edit" element={<ServiceEditor />} /> */}
      <Route path="/edit/order/:id" element={<OrderForm />} />
      <Route path="/past/order/:id" element={<OrderForm />} />
      <Route path="/pending/order/:id" element={<OrderForm />} />
      <Route path="/add/order/:id" element={<OrderForm />} />
      <Route path="/add/order/" element={<OrderForm />} />
      <Route path="/pending/subscription/:id" element={<SubscriptionForm />} />
      <Route path="/edit/subscription/:id" element={<SubscriptionForm />} />
      <Route path="/past/subscription/:id" element={<SubscriptionForm />} />
      <Route path="/area/order/" element={<AreaOrders />} />
      <Route path="/route/:id" element={<DriverRoute />} />
      {/* <Route path="/labels" element={<Labels />} /> */}
      <Route path="/orders" element={<Orders />} />
      <Route path="/subscriptions" element={<Subscriptions />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/reorder" element={<Reorder />} />
      <Route path="/earnings" element={<EarningsCalculator />} />
      <Route path="/holidays" element={<Holidays />} />
      <Route path="/configuration" element={<Configuration />} />
      <Route path="/stripe/authorize" element={<StripeAuthorize />} />
      <Route path="/" element={<SellerDash />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default ServiceRoutes;
