import moment from "moment";
import writeXlsxFile from "write-excel-file";
const checkExpiry = (
  sDate,
  eDate,
  tDate,
  holidays = [],
  selDays = [1, 2, 3, 4, 5],
  validation,
  pause
) => {
  const cDate = tDate ? tDate : moment().format("YYYY-MM-DD");
  if (selDays?.length > 0 && validation === "days") {
    return (
      moment(sDate).isSameOrBefore(cDate) &&
      moment(eDate).isSameOrAfter(cDate) &&
      selDays.find((sd) => sd === moment(tDate).day()) &&
      !pause
    );
  }
  return (
    moment(sDate).isSameOrBefore(cDate) &&
    moment(eDate).isSameOrAfter(cDate) &&
    !pause
  );
};
const checkDate = (sDate, eDate, tDate) => {
  const cDate = tDate ? tDate : moment().format("YYYY-MM-DD");
  return moment(eDate).isAfter(cDate);
};
const createExcel = async (orderArray, columns) => {
  await writeXlsxFile(orderArray, {
    columns,
    fileName: `${moment().format("YYYY-MM-DD")}.xlsx`,
  });
};
const createExcelData = async (arr, date, commaSeparatedString, onlyDriver) => {
  let orderArray = [[]];

  const len = (arr.length - 1).toString();
  orderArray.push([
    {
      type: String,
      value: "Date",
      fontSize: 12,
      fontWeight: "bold",
    },
    {
      type: String,
      value: date,
      backgroundColor: "#03fc3d",
    },
  ]);
  orderArray.push([
    {
      type: String,
      value: "Categories",
      fontSize: 12,
      fontWeight: "bold",
    },
    {
      type: String,
      value: commaSeparatedString,
      backgroundColor: "#fa05b9",
    },
  ]);
  orderArray.push([
    {
      type: String,
      value: "Starting From",
      fontSize: 12,
      fontWeight: "bold",
    },
    {
      type: String,
      value: arr[0].address,
      backgroundColor: "#f8fc03",
    },
  ]);
  orderArray.push([
    {
      type: String,
      value: "Total Orders",
      fontSize: 12,
      fontWeight: "bold",
    },
    {
      type: String,
      value: len,
      backgroundColor: "#fc9403",
    },
  ]);
  orderArray.push([]);
  orderArray.push([]);
  const newArr = arr.slice(1);
  let totalPrice = 0;
  newArr.map((item) => (totalPrice += parseFloat(item.price)));
  orderArray.push([
    {
      type: String,
      value: "Name",
      fontWeight: "bold",
    },
    {
      type: String,
      value: "Address",
      fontWeight: "bold",
    },
    {
      type: String,
      value: "Phone Number",
      fontWeight: "bold",
    },
    {
      type: String,
      value: "Menu Option",
      fontWeight: "bold",
    },

    {
      type: String,
      value: "Request",
      fontWeight: "bold",
    },
    {
      type: String,
      value: "Start Date",
      fontWeight: "bold",
    },
    {
      type: String,
      value: "End Date",
      fontWeight: "bold",
    },
    {
      type: String,
      value: onlyDriver ? "Amount" : "",
      fontWeight: "bold",
    },
  ]);

  newArr.map(
    ({
      FirstName,
      LastName,
      address,
      PhoneNumber,
      menuOpt,
      request,
      sDate,
      calEndDate,
      price,
    }) => {
      orderArray.push([
        {
          type: String,
          value: FirstName + " " + LastName,
        },
        {
          type: String,
          value: address,
        },
        {
          type: String,
          value: PhoneNumber,
        },
        {
          type: String,
          value: menuOpt,
        },
        {
          type: String,
          value: request ? request : "",
          backgroundColor: request ? "#f8fc03" : "",
        },
        {
          type: String,
          value: sDate,
        },
        {
          type: String,
          value: calEndDate,
        },
        {
          type: Number,
          value: price ? parseFloat(price) : 0,
          backgroundColor: !price ? "#fc1303" : "",
        },
      ]);
    }
  );
  orderArray.push([]);
  orderArray.push([
    {},
    {},
    {},
    {},
    {},
    {},
    {
      type: String,
      value: "Total",
      fontWeight: "bold",
    },
    {
      type: Number,
      value: totalPrice,
    },
  ]);
  const columns = [
    { width: 20 },
    { width: 50 },
    { width: 15 },
    { width: 15 },
    { width: 20 },
    { width: 15 },
    { width: 15 },
    { width: 15 },
  ];
  return { orderArray, columns };
};

const getDatesBetween = (start, end) => {
  let datesArray = [];

  let startDate = moment(start);
  let endDate = moment(end);
  while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
    datesArray.push(startDate.format("YYYY-MM-DD")); // Add the date in the desired format
    startDate.add(1, "days"); // Increment the date by 1 day
  }

  return datesArray;
};
function calculateAdjustedEndDate(
  startDate = moment().format("YYYY-MM-DD"),
  workingDays = [1, 2, 3, 4, 5],
  requiredWeekdays = 2,
  pauses = [],
  holidays = []
) {
  // Parse the start date using Moment.js
  let currentMoment = moment(startDate);

  // Convert pause periods and holidays to Moment.js objects
  let pausePeriods = pauses.map((pause) => ({
    start: moment(pause.start),
    end: moment(pause.end),
  }));
  let holidayMoments = holidays.map((holiday) => moment(holiday));

  // Function to check if a day is a working day
  const isWorkingDay = (date) => {
    return workingDays.includes(date.day()); // Use Moment.js 'day()' method
  };

  // Function to check if a day is within any of the pause periods
  const isPausedDay = (date) => {
    return (
      pausePeriods.length > 0 &&
      pausePeriods.some(
        (pause) =>
          date.isSameOrAfter(pause.start) && date.isSameOrBefore(pause.end)
      )
    );
  };

  // Function to check if a day is a holiday
  const isHoliday = (date) => {
    return (
      holidayMoments.length > 0 &&
      holidayMoments.some((holiday) => date.isSame(holiday, "day"))
    );
  };

  // Calculate the new end date by counting valid weekdays
  let validDaysCounted = 0;
  if (requiredWeekdays < 1) {
    requiredWeekdays = 1;
  }
  while (validDaysCounted < requiredWeekdays) {
    if (
      isWorkingDay(currentMoment) &&
      !isPausedDay(currentMoment) &&
      !isHoliday(currentMoment)
    ) {
      validDaysCounted++;
    }
    currentMoment.add(1, "days"); // Increment the day using Moment.js
  }

  // Moment object needs to be formatted to return a date string
  return currentMoment.subtract(1, "days").format("YYYY-MM-DD"); // Adjusting back one day
}
export {
  calculateAdjustedEndDate,
  checkDate,
  checkExpiry,
  createExcel,
  createExcelData,
  getDatesBetween,
};
