import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  Grid,
  Snackbar,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { getAllPaymentModes } from "../api/payment";

const OrderHistory = ({
  openHistory,
  index,
  order,
  data,
  orderDate,
  areas,
  collapsible,
}) => {
  const codeNames = {
    FirstName: "First Name",
    LastName: "Last Name",
    PhoneNumber: "Phone Number",
    rrOpt: "Rice/Roti Options",
    rice: "Rice",
    dal: "Dal",
    sabzi: "Sabzi",
    roti: "Roti",
    driver: "Category",
    pause: "Paused",
    serviceOpt: "Service Type",
    sDate: "Start Date",
    eDate: "End Date",
    calEndDate: "Computed End Date",
    request: "Request",
    address: "Address",
    price: "Price",
    menuOpt: "Menu Type",
    selDays: "Days",
    note: "Personal Notes",
    pauses: "Pauses",
    cancel_at_period_end: "Cancelled Renewal",
    renewalDate: "Renewal Date",
    requiredWeekdays: "Required Days",
  };
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [snack, setSnack] = React.useState(false);
  const [payMode, setPayMode] = React.useState([]);
  const showChangedValues = (values, key) => {
    if (key === "selDays") {
      return values[key].map((ind) => days[ind] + " ");
    } else if (key === "driver") {
      for (const element of areas) {
        if (element.id === values.driver) {
          return element.aname;
        }
      }
    } else if (key === "pauses" && values[key]?.length > 0) {
      return values[key]?.map((ind) => ind?.start + " - " + ind?.end);
    } else if (key === "cancel_at_period_end") {
      if (values[key]) {
        return "ON";
      } else {
        return "OFF";
      }
    } else if (key === "coord") {
      return;
    } else if (key === "_id") {
      return;
    } else if (key === "startDate") {
      return;
    } else if (key === "endDate") {
      return;
    } else if (key === "calculatedEndDate") {
      return;
    } else if (key === "tDate") {
      return;
    } else if (key === "date") {
      return;
    } else if (key === "relatedOrders") {
      return;
    } else if (key === "pause") {
      if (values[key]) {
        return "ON";
      } else {
        return "OFF";
      }
    }
    if (typeof values[key] !== "object") {
      return values[key];
    }
  };
  const getPayModes = async () => {
    const { data } = await getAllPaymentModes();
    setPayMode(data.find((item) => item.value === order?.paymentMode)?.name);
  };
  useEffect(() => {
    getPayModes();
  }, []);
  const getOldNewValues = (item) => {
    const keys = Object.keys(item.newValues);
    return keys.map((key) => (
      <>
        <Snackbar
          open={snack}
          autoHideDuration={2000}
          onClose={() => setSnack(false)}
          message="Copied to clipboard"
        />
        <Grid item xs={12} container>
          <Grid item xs={4}>
            <Typography style={{ fontWeight: "bold" }}>
              <i>{codeNames[key]}</i>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              style={{
                fontWeight: "bold",
                color: "#2E8B57",
              }}
            >
              {showChangedValues(item.newValues, key)}
            </Typography>
          </Grid>

          <Grid
            item
            xs={4}
            style={{
              fontWeight: "bold",
              color: "#FF3131",
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                color: "#2E8B57",
              }}
            >
              <s
                style={{
                  fontWeight: "bold",
                  color: "#FF3131",
                }}
              >
                {showChangedValues(item.oldValues, key)}
              </s>
            </Typography>
          </Grid>
        </Grid>
      </>
    ));
  };

  return !collapsible ? (
    <TableRow style={{ backgroundColor: "#ECE4E2" }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
        <Collapse in={openHistory === index} timeout="auto" unmountOnExit>
          <Box style={{ padding: 60 }}>
            {data ? (
              data.map((item) => {
                if (
                  [
                    "coord",
                    "_id",
                    "startDate",
                    "endDate",
                    "calculatedEndDate",
                    "tDate",
                    "date",
                    "relatedOrders",
                    "pause",
                  ].some((value) => Object.keys(item.newValues).includes(value))
                ) {
                  return;
                }

                return (
                  <>
                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                      <KeyboardArrowUpIcon fontSize="large" />
                    </Grid>
                    {getOldNewValues(item)}
                    <Typography style={{ marginTop: 30 }}>
                      {new Date(item.updatedOn.toString()).toLocaleString()}
                    </Typography>
                  </>
                );
              })
            ) : (
              <></>
            )}
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <KeyboardArrowUpIcon fontSize="large" />
            </Grid>
            <Typography style={{ marginTop: 20 }}>
              <i>Added to the system</i>
            </Typography>
            <Typography>
              {new Date(orderDate?.toString()).toLocaleString()}
            </Typography>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  ) : (
    <>
      {data ? (
        data.map((item) => {
          return (
            <>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <KeyboardArrowUpIcon fontSize="large" />
              </Grid>
              {getOldNewValues(item)}
              <Typography style={{ marginTop: 30 }}>
                {new Date(item.updatedOn.toString()).toLocaleString()}
              </Typography>
            </>
          );
        })
      ) : (
        <></>
      )}
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <KeyboardArrowUpIcon fontSize="large" />
      </Grid>
      {/* {order?.isPaid && order?.paymentMode === "card" ? (
        <>
          <i
            style={{
              fontSize: 20,
              marginTop: 20,
              marginBottom: 10,
              fontWeight: "bold",
            }}
          >
            Paid online
          </i>
          <Grid item xs={12}>
            <Typography gutterBottom>
              Total Paid - C$ {order.totalPrice}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>
              Service Fees - C$ {order.serviceFee}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom>Net Payout - C$ {order.payout}</Typography>
          </Grid>
        </>
      ) : order?.paymentMode === "manual" ? (
        <i
          style={{
            fontSize: 20,
            marginTop: 20,
            marginBottom: 10,
            fontWeight: "bold",
          }}
        >
          Manually added
        </i>
      ) : (
        <>
          <Grid item xs={12}>
            <i
              style={{
                fontSize: 20,
                marginTop: 20,
                marginBottom: 10,
                fontWeight: "bold",
              }}
            >
              Paid by {payMode}
            </i>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span>
                <span style={{ fontWeight: "bolder" }}>Confirmation</span> -{" "}
                {order?.confNum ? order?.confNum : order?.previewData?.confNum}
                <span
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      order?.confNum
                        ? order?.confNum
                        : order?.previewData?.confNum
                    );
                    setSnack(true);
                  }}
                >
                  <ContentCopyIcon />
                </span>
              </span>
            </Typography>
          </Grid>
        </>
      )} */}
      <Grid item xs={12}>
        <Typography style={{ marginTop: 20 }}>
          <i>Added to the system</i>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {new Date(orderDate?.toString()).toLocaleString()}
        </Typography>
      </Grid>
    </>
  );
};

export default OrderHistory;
