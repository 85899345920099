import { AppBar } from "@mui/material";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import React, { useContext } from "react";

import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import DataContext from "./api/context";
const EnableColorOnDarkAppBar = () => {
  const navigate = useNavigate();
  const { setOpen, user, setSide } = useContext(DataContext);
  const drawerWidth = 350;
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#1976d2",
      },
    },
  });
  return (
    <Stack sx={{ flexGrow: 1, marginBottom: 2 }}>
      {/* <ThemeProvider theme={darkTheme}> */}
      <AppBar
        position="static"
        elevation={0}
        style={{ backgroundColor: "white" }}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <SideBar />
          {/* <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer", color: "black" }}
            onClick={() => navigate("/")}
          >
            DT Meals
          </Typography> */}
          {/* <Button color="inherit" onClick={() => setOpen(true)}>
      Login/SignUp
    </Button> */}
        </Toolbar>
      </AppBar>
      {/* </ThemeProvider> */}
    </Stack>
  );
};

export default EnableColorOnDarkAppBar;
