import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "./api/context";
import { getMenuBySelId } from "./api/menu";
import {
  checkStripeAccessToken,
  getOnboardUrl,
  getStripeAcc,
} from "./api/seller";
import Layout from "./shared/Layout";
import Loading from "./shared/Loading";
const Home = () => {
  const navigate = useNavigate();
  const { user } = useContext(DataContext);
  const [needInfo, setNeedInfo] = useState(true);
  const [verified, setVerified] = useState(true);
  const [listed, setListed] = useState("menu");
  const [menuUrl, setMenuUrl] = useState();
  const [stripeAccess, setStripeAccess] = useState(true);

  const stripeInfoRequired = async () => {
    const { data } = await getStripeAcc(user.PayAccount);
    setNeedInfo(data.charges_enabled && data.details_submitted);
  };
  const getRequirements = async () => {
    const { data } = await getMenuBySelId(user.SelID);
    setListed(data);
    if (data?._id) {
      setMenuUrl("https://www.dtmeals.com/menu/" + data?._id);
    }
    setVerified(data.isVerified);
  };
  const checkStripeAccessTokenReq = async () => {
    const { data } = await checkStripeAccessToken(user.SelID);
    setStripeAccess(data);
  };
  useEffect(() => {
    if (user.SelID) {
      stripeInfoRequired();
      getRequirements();
      checkStripeAccessTokenReq();
    }
  }, [user]);
  const redirectToReq = async () => {
    const { data } = await getOnboardUrl(user.PayAccount);
    window.location.href = data.url;
  };
  const redirectToConnect = async () => {
    let client_id = process.env.REACT_APP_STRIPE_CLIENT_ID;
    window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${client_id}&scope=read_write`;
  };
  return (
    <>
      <Layout>
        <Grid container>
          {/* <Grid item xs={12} style={{ marginBottom: 20 }}>
          <div>
            <Typography
              style={{
                fontFamily: "'Dancing Script', cursive",
                fontSize: 25,
              }}
              gutterBottom
            >
              {timeGreetings.timeGreetingsAutoDetectTime()}, {user.FirstName}{" "}
              {user.LastName}
            </Typography>
          </div>
        </Grid> */}
          {window.location.pathname === "/" && (
            <>
              {!needInfo && (
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <Paper
                    variant="outlined"
                    style={{ padding: "20px", height: "50px" }}
                  >
                    <div>
                      <Typography>
                        You have some payout information missing which would
                        stop future payments.
                      </Typography>
                    </div>
                    <div>
                      <Button
                        style={{ paddingLeft: "0px" }}
                        onClick={() => redirectToReq()}
                      >
                        Complete Information
                      </Button>{" "}
                    </div>
                  </Paper>
                </Grid>
              )}
              {needInfo && !stripeAccess && (
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <Paper
                    variant="outlined"
                    style={{ padding: "20px", height: "50px" }}
                  >
                    <div>
                      <Typography>
                        Your stripe account is not connected to DT Meals. Please
                        connect it to start taking online orders.
                      </Typography>
                    </div>
                    <div>
                      <Button
                        style={{ paddingLeft: "0px" }}
                        onClick={() => redirectToConnect()}
                      >
                        Connect now
                      </Button>{" "}
                    </div>
                  </Paper>
                </Grid>
              )}
              {!verified && listed && (
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: "20px",
                      height: "50px",
                      borderColor: "red",
                    }}
                  >
                    <div>
                      <Typography>
                        Your listing is not verified and hence, it is not active
                        currently. Call us at +1 705-770-3061 (6 pm - 9 pm) to
                        get verified.
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
              )}
              {/* {!listed && (
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: "20px",
                      height: "50px",
                      borderColor: "red",
                    }}
                  >
                    <div>
                      <Typography>
                        Let's get started with your delicious menu
                      </Typography>
                    </div>
                    <div>
                      <Button
                        style={{ paddingLeft: "0px" }}
                        onClick={() => navigate("/edit")}
                      >
                        Write Menu
                      </Button>{" "}
                    </div>
                  </Paper>
                </Grid>
              )} */}
            </>
          )}
          <Loading />
        </Grid>
      </Layout>
    </>
  );
};

export default Home;
