import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Grid, Snackbar, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { getAllPaymentModes } from "./api/payment";
const OrderPreview = ({ order }) => {
  const [payMode, setPayMode] = useState([]);
  console.log(order);
  const [snack, setSnack] = useState(false);

  const getPayModes = async () => {
    const { data } = await getAllPaymentModes();
    setPayMode(data.find((item) => item.value === order?.paymentMode)?.name);
  };
  useEffect(() => {
    getPayModes();
  }, []);

  return (
    <>
      {" "}
      <Grid item xs={12} container direction="column">
        <Snackbar
          open={snack}
          autoHideDuration={2000}
          onClose={() => setSnack(false)}
          message="Copied to clipboard"
        />
        <Grid item xs={12} container alignItems="center">
          <ListAltIcon fontSize="large" />

          <i
            style={{
              fontSize: 30,
              fontWeight: "bold",
              marginLeft: 5,
            }}
          >
            {order?.FirstName} {order?.LastName}'s Order
          </i>
        </Grid>
        <i
          style={{
            fontSize: 20,
            marginTop: 20,
            marginBottom: 10,
            fontWeight: "bold",
          }}
        >
          Summary
        </i>
        {order.prodType?.prodName && (
          <Typography>{order.prodType?.prodName}</Typography>
        )}
        {order.menuOpt?.menuType && (
          <Typography>{order.menuOpt?.menuType}</Typography>
        )}
        {order.selPlan?.planName && (
          <Typography>{order.selPlan?.planName}</Typography>
        )}
        {order.rrOpt && (
          <Typography>
            {order.rrOpt?.roti > 0 ? order.rrOpt?.roti : "no"} roti &{" "}
            {order.rrOpt?.rice > 0 ? order.rrOpt?.rice : "no"} rice
          </Typography>
        )}

        {order.menuOpt?.extrasOpt?.map((key) => {
          return (
            parseInt(order["x" + key]) > 0 && (
              <Typography key={key}>
                {order["x" + key]}
                {" Extra " + _.capitalize(key)}
              </Typography>
            )
          );
        })}
        {order?.request && (
          <Typography>
            <span>
              **<strong>{order?.request}</strong>**
            </span>
          </Typography>
        )}
        {order?.address && <Typography>{order?.address}</Typography>}
        {!!order.selPlan?.days && (
          <Typography>
            <span>{order.sDate}</span>
            <i style={{ marginInline: 5 }}>{"  to  "}</i>
            <span>{order?.eDate}</span>
          </Typography>
        )}
        <i
          style={{
            fontSize: 20,
            marginTop: 20,
            marginBottom: 10,
            fontWeight: "bold",
          }}
        >
          Payment Summary
        </i>
        <Typography>
          Cost{" "}
          <span
            style={{
              marginLeft: 20,
            }}
          >
            <span style={{ marginRight: 2 }}>C$</span>
            {order.cost}
          </span>
        </Typography>
        {order?.coupon?.name && (
          <Typography>
            <span style={{ color: "#45a149" }}>{order?.coupon?.name} </span> -
            C$
            {parseFloat(
              (parseFloat(order.cost) * order?.coupon?.percent_off) / 100
            ).toFixed(2)}{" "}
            ({order?.coupon?.percent_off}% off)
          </Typography>
        )}
        <Typography>
          Tax{" "}
          <span
            style={{
              marginLeft: 20,
            }}
          >
            <span style={{ marginRight: 2 }}>C$</span>
            {order.tax}
          </span>
        </Typography>

        <div
          style={{
            width: "112px",
            height: "7px",
            position: "relative",
            borderBottom: "1px solid #000000",
            marginBottom: 10,
          }}
        ></div>

        <Typography>
          Total{" "}
          <span
            style={{
              marginLeft: 20,
            }}
          >
            <span style={{ marginRight: 2 }}>C$</span>
            {order.totalPrice}
          </span>
        </Typography>

        {order?.isPaid && order?.paymentMode === "card" ? (
          <>
            <i
              style={{
                fontSize: 20,
                marginTop: 20,
                marginBottom: 10,
                fontWeight: "bold",
              }}
            >
              Paid online
            </i>
            <Typography gutterBottom>
              Total Paid{" "}
              <span
                style={{
                  marginLeft: 20,
                }}
              >
                <span style={{ marginRight: 2 }}>C$</span>
                {order.totalPrice}
              </span>
            </Typography>
            <Typography gutterBottom>
              Service Fees{" "}
              <span
                style={{
                  marginLeft: 20,
                }}
              >
                <span style={{ marginRight: 2 }}>C$</span>
                {order.serviceFee ? order.serviceFee : 0}
              </span>
            </Typography>
            <div
              style={{
                width: "200px",
                height: "7px",
                marginBottom: 10,
                position: "relative",
                borderBottom: "1px solid #000000",
              }}
            ></div>
            <Typography gutterBottom>
              Net Payout
              <span
                style={{
                  marginLeft: 20,
                }}
              >
                <span style={{ marginRight: 2 }}>C$</span>
                {order.payout}
              </span>
            </Typography>
          </>
        ) : (
          <>
            <i
              style={{
                fontSize: 20,
                marginTop: 20,
                marginBottom: 10,
                fontWeight: "bold",
              }}
            >
              Paid by {payMode}
            </i>
            <Typography>
              <span>
                <span style={{ fontWeight: "bolder" }}>Confirmation</span> -{" "}
                {order?.confNum}
                <span
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  onClick={() => {
                    navigator.clipboard.writeText(order?.confNum);
                    setSnack(true);
                  }}
                >
                  <ContentCopyIcon />
                </span>
              </span>
            </Typography>
          </>
        )}
      </Grid>
    </>
  );
};

export default OrderPreview;
