import http from "./httpService";
import api from "./config";
import { encryptPayload } from "./encrypt";

const sendMessage = async (values) => {
  const result = await http.post(api + "/send/message", encryptPayload(values));
  return result;
};

export { sendMessage };
