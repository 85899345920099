import CheckIcon from "@mui/icons-material/Check";
import TextsmsIcon from "@mui/icons-material/Textsms";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { blue, green, red } from "@mui/material/colors";
import * as React from "react";
import { defaultMessages } from "../api/constants";
import DataContext from "../api/context";
import { getMenuBySelId } from "../api/menu";
import { sendMessage } from "../api/message";

const theme = createTheme({
  palette: {
    whatsapp: {
      main: green[500],
      contrastText: "#fff",
    },
    textMessage: {
      main: blue[500],
      contrastText: "#fff",
    },
    email: {
      main: red[500],
      contrastText: "#fff",
    },
  },
});

const useStyles = {
  button: {
    borderRadius: "20px",
    padding: "10px 20px",
    boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
    },
  },
};

export default function CustomMessage({ data }) {
  const [open, setOpen] = React.useState(false);
  const { user } = React.useContext(DataContext);
  const [messages, setMessages] = React.useState(defaultMessages);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [msg, setMsg] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = (type) => {
    sendMessage({
      message: msg,
      data: data,
      SelID: user.SelID,
      methods: [type],
    });
    setOpen(false);
  };

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
    setMsg(messages[index]);
  };

  React.useEffect(() => {
    const fetchMessages = async () => {
      const {
        data: { messageTemplates },
      } = await getMenuBySelId(user?.SelID);

      if (messageTemplates) {
        setMessages([...messages, ...messageTemplates]);
      }
    };
    fetchMessages();
  }, [user?.SelID]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Button onClick={handleClickOpen}>Custom message</Button>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle
            style={{ fontWeight: "bold", marginLeft: "15px", color: "#9f6ba0" }}
          >
            Send Custom Message
          </DialogTitle>

          <DialogContent>
            <List>
              {messages.map((msg, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => handleListItemClick(index)}
                >
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      paddingInline: "10px",
                      width: "100%",
                      backgroundColor:
                        selectedIndex === index ? "#e1d0e1" : "#f9f9f9",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ListItemText
                      primary={
                        <pre style={{ whiteSpace: "pre-wrap" }}>{msg}</pre>
                      }
                    />
                    {selectedIndex === index && <CheckIcon />}
                  </Box>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions style={{ marginRight: "25px", marginBottom: "20px" }}>
            <Button
              variant="contained"
              color="whatsapp"
              startIcon={<WhatsAppIcon />}
              sx={useStyles.button}
              onClick={() => handleSend("whatsapp")}
            >
              WhatsApp
            </Button>
            <Button
              variant="contained"
              color="textMessage"
              startIcon={<TextsmsIcon />}
              sx={useStyles.button}
              onClick={() => handleSend("text")}
            >
              Text Message
            </Button>
            {/* <Button
              variant="contained"
              color="email"
              startIcon={<EmailIcon />}
              sx={useStyles.button}
              onClick={() => handleSend("email")}
            >
              Email
            </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
}
