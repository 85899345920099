import AddIcon from "@mui/icons-material/Add";
import { Alert, Grid, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useEffect, useState } from "react";
import { getArea, updateArea } from "./api/area";
import DataContext from "./api/context";
const ResponsiveDialog = ({
  open,
  setOpen,
  upCat,
  items,
  setUpCat,
  setCategories,
}) => {
  const theme = useTheme();
  const [itemText, setItemText] = useState(upCat?.aname);
  const [error, setError] = useState();
  const { user } = useContext(DataContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    setItemText(upCat?.aname);
  }, [upCat]);
  useEffect(() => {
    setItemText(upCat?.aname);
  }, [open]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function updateObjectById(objectId, newData) {
    // Find the object with the specified ID
    let tempItems = items;
    let foundObject = tempItems.find((obj) => obj.id === objectId);

    // If the object is found, update its data
    if (foundObject && newData) {
      foundObject.aname = newData;
      return items;
    } else if (newData) {
      tempItems.push({ id: objectId, aname: newData });
      return items;
    }
    return "Not valid";
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography
          style={{ marginBottom: 10, fontWeight: "bold", fontSize: 20 }}
        >
          Category - {upCat?.id}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {upCat?.id && (
          <DialogContentText>
            <TextField
              value={itemText}
              onChange={(e) => {
                setItemText(e.target.value);
              }}
            ></TextField>
          </DialogContentText>
        )}
        {error && !itemText && <Alert severity="error">{error}</Alert>}
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          onClick={async () => {
            let newCat = updateObjectById(upCat.id, itemText);
            if (typeof newCat === "string") {
              setError(newCat);
            } else {
              setCategories(newCat);
              await updateArea({ SelID: user.SelID, areas: newCat });
              handleClose();
            }
          }}
        >
          Save
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
const ScrollableList = ({ heading, items, setOpen, setUpCat }) => {
  return (
    <div style={styles.scrollableList}>
      <>
        <div>
          <h2 style={{ marginLeft: 10, color: "#9F6BA0" }}>
            {heading || ""}
            <span
              style={{ float: "right", cursor: "pointer" }}
              onClick={() => {
                setUpCat({ id: (items.length + 1).toString(), aname: "" });
                setOpen(true);
              }}
            >
              <AddIcon />
            </span>
          </h2>
        </div>
        <body style={{ marginLeft: 10, marginBottom: 20 }}>
          Select to update
        </body>
        <div style={styles.listContainer}>
          <ul style={styles.list}>
            {items?.length > 0 ? (
              items?.map((item, index) => (
                <li key={index} style={styles.listItem}>
                  <div
                    onClick={() => {
                      setUpCat(item);
                      setOpen(true);
                    }}
                  >
                    <Typography>
                      {item.id} - {item.aname}
                    </Typography>
                  </div>
                  {/* <div style={styles.secondaryText}>
                  {item?.description ? item?.description : "No description added"}
                </div> */}
                </li>
              ))
            ) : (
              <Typography
                variant="body2"
                style={{ marginLeft: 10, marginBottom: 10 }}
              >
                No categories added
              </Typography>
            )}
          </ul>
        </div>
      </>
    </div>
  );
};
const Categories = () => {
  const [open, setOpen] = useState(false);
  const [upCat, setUpCat] = useState();
  const { user } = useContext(DataContext);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const getAreas = async () => {
      const { areas } = await getArea(user.SelID);
      setCategories(areas);
    };
    getAreas();
  }, [user.SelID]);
  useEffect(() => {}, [categories, open, upCat]);
  return (
    <Grid item xs={12} container justifyContent="center" alignItems="center">
      <ScrollableList
        heading="Categories"
        items={categories}
        setOpen={setOpen}
        setUpCat={setUpCat}
      />
      {categories?.length > 0 && upCat && (
        <ResponsiveDialog
          open={open}
          setOpen={setOpen}
          upCat={upCat}
          items={categories}
          setUpCat={setUpCat}
          setCategories={setCategories}
        />
      )}
    </Grid>
  );
};
const styles = {
  scrollableList: {
    height: "500px",
    maxWidth: "500px",
    minWidth: "300px",
    overflowY: "scroll",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  listContainer: {
    maxWidth: "300px",
    margin: "auto",
  },
  list: {
    listStyle: "none",
    padding: "0",
    margin: "0",
  },
  listItem: {
    padding: "12px",
    borderBottom: "1px solid #eee",
    fontSize: "16px",
    cursor: "pointer",
  },
  secondaryText: {
    color: "#666",
    fontSize: "14px",
    marginTop: "4px",
  },
};
export default Categories;
