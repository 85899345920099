import { Button } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import writeXlsxFile from "write-excel-file";
import DataContext from "./api/context";
const PrintExcel = ({ data }) => {
  const { setLoading } = useContext(DataContext);
  const fetchOrders = async () => {
    setLoading(true);
    let orderArray = [];
    data.map((order) => {
      order?.request
        ? orderArray.push([
            {
              type: String,
              value: order.FirstName,
            },
            {
              type: String,
              value: order.address,
            },
            {
              type: String,
              value: order.PhoneNumber,
            },
            {
              type: String,
              value: order.menuOpt,
            },
            {
              type: String,
              value: order?.request,
            },
          ])
        : orderArray.push([
            {
              type: String,
              value: order.FirstName,
            },
            {
              type: String,
              value: order.address,
            },
            {
              type: String,
              value: order.PhoneNumber,
            },
            {
              type: String,
              value: order.menuOpt,
            },
          ]);
    });
    await writeXlsxFile(orderArray, {
      fileName: "file.xlsx",
    });
    setLoading(false);
  };
  return (
    <>
      <Button onClick={() => fetchOrders()}>Convert to Excel</Button>
    </>
  );
};

export default PrintExcel;
