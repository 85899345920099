import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import { getArea } from "./api/area";
import DataContext from "./api/context";

export default function AreaSelect({
  id,
  value,
  label,
  onChange,
  name,
  options,
  error,
  style,
  helperText,
}) {
  const [areas, setAreas] = React.useState([]);
  const { user } = React.useContext(DataContext);
  const fetchOptions = async () => {
    let arr = [];
    const { areas } = await getArea(user.SelID);
    setAreas(areas);
  };
  React.useEffect(() => {
    fetchOptions();
  }, [user]);
  return (
    <>
      {areas && (
        <FormControl fullWidth style={style}>
          <InputLabel id={id}>{label}</InputLabel>
          <Select
            labelId={id}
            id={id}
            value={value}
            label={label}
            name={name}
            onChange={onChange}
            error={error}
            helperText={helperText}
          >
            {areas.map((option) => (
              <MenuItem value={option.id}>
                Category - {option.id} {option.aname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}
