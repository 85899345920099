import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

export function LineChart({ valuesArray, chartLabels, prd = "default" }) {
  let lineConfig = {
    default: {
      label: "Default",
      borderColor: "rgb(159, 107, 160)",
      backgroundColor: "rgba(159, 107, 160, 0.5)",
    },
    orders: {
      label: "Orders",
      borderColor: "rgb(159, 107, 160)",
      backgroundColor: "rgba(159, 107, 160, 0.5)",
    },
    revenue: {
      fill: true,
      label: "Revenues",
      borderColor: "rgb(63, 192, 96)",
      backgroundColor: "rgba(63, 192, 96, 0.5)",
    },
  };
  let data = {};
  if (valuesArray.length > 0) {
    data = {
      labels: chartLabels,
      datasets: [
        {
          ...lineConfig[prd],
          data: valuesArray,
        },
      ],
    };
  }
  return valuesArray.length > 0 && <Line options={options} data={data} />;
}
