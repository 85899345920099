import { Grid, Typography } from "@mui/material";
import React from "react";
import about from "./about.png";
const About = () => {
  return (
    <>
      <Grid item xs={12} container style={{ marginTop: 70 }}>
        <Grid
          item
          md={12}
          lg={6}
          container
          justifyContent="center"
          style={{ padding: 20 }}
        >
          <img src={about} alt="About" style={{ height: 400, width: 400 }} />
        </Grid>
        <Grid item md={12} lg={6} style={{ padding: 20 }}>
          <Typography variant="h3" style={{ marginBottom: 30 }}>
            About Us
          </Typography>
          <Typography style={{ fontSize: 25 }}>
            Most of the meal subscriptions operators shut down their business
            operations in the first 6 months due to its overwhelming operations.
            Restaurant owners are not able to handle the meal subscription
            service’s complicated system alongside their business operation.
            This is where DT Meals’ software comes into play.
          </Typography>
          <Typography style={{ marginTop: 20, fontSize: 25 }}>
            {" "}
            Launched in 2023, Our technology platform connects customers and
            partners serving their multiple needs. We provide meal subscription
            partners with industry-specific tools which enable them to engage
            and acquire customers to grow their business while also providing a
            reliable and efficient delivery service. Our software is user
            friendly.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default About;
